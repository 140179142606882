/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { INotification } from '../../../core/components/messaging/entities/Notification';
import { canDebugReport } from '../../../reporting/entities/Report';
import { ProcessingPage, logProcessingPage } from '../../../reporting/entities/ReportProcessing';
import { useGetReport } from '../../../reporting/services/ReportService';
import TokenValidation from '../../components/report-common/TokenValidation';
import { ICubeRequest } from '../../entities/api/CubeRequest';

interface IPageInfo {
    setNotification: (n: INotification) => void;
    processingStep: ProcessingPage;
    setProcessingStep: (i: ProcessingPage) => void;
    setPageCubeRequest?: (i: ICubeRequest) => void;
}

const PageTemplate: React.FunctionComponent<IPageInfo> = ({
    setNotification, setProcessingStep, processingStep
}) => {
    const { service: reportService, setItemId: setGetReportId } = useGetReport();

    const [debug] = useState<boolean>(canDebugReport);
    const [validToken, setValidToken] = useState<boolean>(false);

    useEffect(() => {
        if (validToken) {
            setProcessingStep(ProcessingPage.PageStart);
        }
        // eslint-disable-next-line
    }, [validToken]);


    useEffect(() => {
        logProcessingPage(processingStep, debug);
        switch (processingStep) {
            case ProcessingPage.PageStart:
                setProcessingStep(ProcessingPage.PageLoad);
                break;

            case ProcessingPage.PageFiltersLoaded:
                setProcessingStep(ProcessingPage.DossierFiltersLoad);
                break;
        }
        // eslint-disable-next-line
    }, [processingStep]);
    return (
        <TokenValidation validToken={validToken} setValidToken={setValidToken} />
    );
};

export default PageTemplate;
