/* eslint-disable react/jsx-no-undef */
import React, { Fragment, useEffect, useState } from 'react';
import { INotification } from '../../../core/components/messaging/entities/Notification';
import { IReportGroup } from '../../../reporting/entities/ReportGroup';
import { IMicroStrategyDossierChapterList } from '../../entities/MicrostrategyDossierChapter';
import { getDossierFilters } from '../../services/MicrostrategyDossierService';
import MicrostrategyPageAbout from './MicrostrategyPageAbout';
import MicrostrategyPageSection from './MicrostrategyPageSection';
declare global { var msLogin: any; }

interface IComponentInfo {
    chapterList: IMicroStrategyDossierChapterList;
    dossier: any;
    reportID: string;
    onUpdateFilters?: (p: any) => void;
    initFilters?: () => void;
    setNotification?: (n: INotification) => void;
    onPageChanged: () => void;
    aboutPages?: IReportGroup[];
    aboutIndex: number;
    setAboutIndex: (i: number) => void;
    setCurrentChapterIndex?: (i: number) => void;
}

export const MicrostrategyPageLinks: React.FunctionComponent<IComponentInfo> = ({ reportID, aboutIndex, setAboutIndex, chapterList, dossier, onUpdateFilters, onPageChanged, aboutPages, setCurrentChapterIndex }) => {
    const [currentIndex, setCurrentIndex] = useState<string>('0-0');

    const onIndexChanged = (i: string) => {
        if (currentIndex !== i) {
            setCurrentIndex(i);
            setAboutIndex(-1);
            onPageChanged();
            setCurrentChapterIndex?.(parseInt(i.split('-')[0],10));
        }
    }

    const onAboutChanged = (i: number) => {
        if (aboutIndex !== i) {
            setCurrentIndex('');
            setAboutIndex(i);
        }
    }

    useEffect(() => {
        onUpdateFilters && getDossierFilters(onUpdateFilters, dossier);
        // eslint-disable-next-line
    }, [dossier]);

    useEffect(() => {
        setCurrentIndex('0-0');
        // eslint-disable-next-line
    }, [reportID]);

    return (
        <>
            {chapterList.totalPages > 1 && (
                <nav className="m-b-0 mb-3 pull-right" role="tablist">
                    {chapterList.chapters.map((c, index) =>
                        <Fragment key={index} >
                            {c.children.map((p, index2) =>
                                <MicrostrategyPageSection
                                    page={p}
                                    chapter={c}
                                    currentIndex={currentIndex}
                                    pageIndex={index2}
                                    key={index2}
                                    chapterIndex={index}
                                    isLink={true}
                                    setCurrentIndex={onIndexChanged}
                                    dossier={dossier}
                                />
                            )
                            }
                        </Fragment>
                    )}
                    {aboutPages?.map((i, index) =>
                        <MicrostrategyPageAbout
                            currentIndex={index}
                            aboutIndex={aboutIndex}
                            key={index}
                            setAboutIndex={onAboutChanged}
                            item={i}
                            isLink={true}
                        />

                    )}

                </nav>
            )}
        </>
    );
};


export default MicrostrategyPageLinks;

