/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import OEIcon from '../../../core/components/general/OEIcon';
import { OECol } from '../../../core/components/grid/OECol';
import { OERow } from '../../../core/components/grid/OERow';
import OEMessage from '../../../core/components/messaging/OEMessage';
import OESpinner, { SpinnerStyle } from '../../../core/components/messaging/OESpinner';
import { INotification } from '../../../core/components/messaging/entities/Notification';
import { MessageType } from '../../../core/components/messaging/enums/InformationMessages';
import { Icon } from '../../../core/entities/Icon';
import { emptyGUID } from '../../../core/utilities/String';
import { parseQueryString } from '../../../core/utilities/URL';
import AboutSection from '../../../report-microstrategy/components/report-common/AboutSection';
import MicrostrategyPageLinks from '../../../report-microstrategy/components/report-common/MicrostrategyPageLinks';
import MicrostrategyPageTabs from '../../../report-microstrategy/components/report-common/MicrostrategyPageTabs';
import ReportExport from '../../../report-microstrategy/components/report-common/ReportExport';
import { IDossierCreation, IDossierCreationFilter, IDossierCreationFilterSelection, IDossierSettings, defaultDossierCreation, defaultDossierSettings } from '../../../report-microstrategy/entities/MicroStrategyDossier';
import { IMicroStrategyExportOptions } from '../../../report-microstrategy/entities/MicroStrategyExportOptions';
import { IMicroStrategyFilter } from '../../../report-microstrategy/entities/MicroStrategyFilters';
import { IMicroStrategyDossierChapterList, defaultMicroStrategyDossierChapterList } from '../../../report-microstrategy/entities/MicrostrategyDossierChapter';
import { IMicroStrategyDossierFilter, IMicroStrategyDossierFilterSelection, MicroStrategyDossierFilterType, MicroStrategySingleSelectAllValue, isSuppressionFilter } from '../../../report-microstrategy/entities/MicrostrategyDossierFilter';
import { ICubeRequest, defaultCubeRequest } from '../../../report-microstrategy/entities/api/CubeRequest';
import { ICubeAttribute } from '../../../report-microstrategy/entities/api/CubeResponse';
import { IDossierDefinition, IDossierDefinitionFilter, getDefaultDossierDefinition } from '../../../report-microstrategy/entities/api/DossierDefinition';
import { createDossier, createPublicDossier, exportDossier, getDossierChapterList } from '../../../report-microstrategy/services/MicrostrategyDossierService';
import { useGetDossierDefinition } from '../../../report-microstrategy/services/MicrostrategyService';
import ReportDescription from '../../../reporting/components/ReportDescription';
import ReportFlag from '../../../reporting/components/report-flag/ReportFlag';
import { IReport, canDebugReport, defaultReport, getReportCubeRequest } from '../../../reporting/entities/Report';
import { IReportConfigurations, ReportConfigurationPaginationStyles, ReportConfigurationType, getReportConfigurationValue } from '../../../reporting/entities/ReportConfiguration';
import { IReportFlag, defaultReportFlag } from '../../../reporting/entities/ReportFlag';
import { IReportGroup, ReportGroupType } from '../../../reporting/entities/ReportGroup';
import { ProcessingPage, ProcessingReport, logProcessingReport, reportLogToConsoleNotification } from '../../../reporting/entities/ReportProcessing';
import { IReportSizing, defaultReportSizing } from '../../../reporting/entities/ReportSizing';
import { useGetReport } from '../../../reporting/services/ReportService';
import DossierFilters from './DossierFilters';

interface IDossierFilters {
    key: string;
    id: string;
    selection: string;
    filterBy: boolean;
    name: string;
    rangeIncrements: string;
    pageFilter: boolean;
}

const isSelectAll = (i: string): boolean => {
    return i.includes(MicroStrategySingleSelectAllValue);
}

interface IReportInfo {
    reportId: string;
    setNotification: (n: INotification) => void;
    pageFilters: IMicroStrategyFilter[];
    pageProcessingStep: ProcessingPage;
    configurations: IReportConfigurations;
    divId: string;
    groupType: string;
    cubeAttributes: ICubeAttribute[];

    isPublic?: boolean;
    pageSettings?: IDossierSettings;
    reportHeight: number;
    setFooter?: (i: string) => void;
    aboutPages?: IReportGroup[];
    multipleReports?: boolean;
}

const Report: React.FunctionComponent<IReportInfo> = ({
    reportId, setNotification, pageFilters, pageProcessingStep, isPublic, aboutPages,
    configurations, divId, pageSettings, reportHeight, setFooter,
    groupType, multipleReports, cubeAttributes
}) => {
    const params: any = parseQueryString();

    const { service: reportService, setItemId: setGetReportId } = useGetReport();
    const { service: dossierGetDefinitionService, setCube: setDossierDefinitionRequest } = useGetDossierDefinition();
    const [dossierDefinition, setDossierDefinition] = useState<IDossierDefinition>(getDefaultDossierDefinition());

    const exportReportRef = useRef<any>();

    const [message, setMessage] = useState<string>('');
    const [debugMessage, setDebugMessage] = useState<string>('');
    const [error] = useState<string>('');
    const [debug] = useState<boolean>(canDebugReport());
    const [initialized, setInitialized] = useState<boolean>(false);
    const [path, setPath] = useState<string>('');
    const [dossier, setDossier] = useState<any>();
    const [report, setReport] = useState<IReport>(defaultReport);

    const [dossierHeight, setDossierHeight] = useState<string>('500px');
    const [reportSizing, setReportSizing] = useState<IReportSizing>(defaultReportSizing);
    const [processingStep, setProcessingStep] = useState<ProcessingReport>(ProcessingReport.Idle);
    const [paginationLinks, setPaginationLinks] = useState<boolean>(false);

    const [chapterList, setChapterList] = useState<IMicroStrategyDossierChapterList>(defaultMicroStrategyDossierChapterList);
    const [chapterIndex, setChapterIndex] = useState<number>(0);
    const [aboutIndex, setAboutIndex] = useState<number>(-1);
    const [exportOptions, setExportOptions] = useState<IMicroStrategyExportOptions[]>([]);
    const [showDescription, setShowDescription] = useState<boolean>(false);
    const [flagging, setFlagging] = useState<boolean>(false);
    const [flag, setFlag] = useState<IReportFlag>(defaultReportFlag);
    const [cubeRequest, setCubeRequest] = useState<ICubeRequest>({ ...defaultCubeRequest });

    const [dossierFilters, setDossierFilters] = useState<IMicroStrategyFilter[]>([]);
    const [localDossierFilters, setLocalDossierFilters] = useState<IDossierFilters[]>([]);

    useEffect(() => {
        switch (pageProcessingStep) {
            case ProcessingPage.PageFiltersLoaded:
            case ProcessingPage.UpdateDossierFilters:
                setProcessingStep(ProcessingReport.ReportLoad);
                setMessage('Loading Report');
                break;
        }
        // eslint-disable-next-line
    }, [pageProcessingStep]);

    useEffect(() => {
        if (report.id !== reportId && !emptyGUID(reportId) && pageProcessingStep === ProcessingPage.Complete) {
            setProcessingStep(ProcessingReport.ReportLoad);
        }
        // eslint-disable-next-line
    }, [reportId]);

    useEffect(() => {
        logProcessingReport(processingStep, debug);
        switch (processingStep) {

            case ProcessingReport.ReportLoad:
                if (reportId !== report.id || emptyGUID(report.id)) {
                    setGetReportId(reportId);
                    setChapterList(defaultMicroStrategyDossierChapterList);
                    setExportOptions([]);
                    setProcessingStep(ProcessingReport.ReportLoading);
                } else {
                    setProcessingStep(ProcessingReport.ReportLoaded);
                }
                break;

            case ProcessingReport.ReportLoaded:
                setProcessingStep(ProcessingReport.DossierFiltersLoad);
                break;

            case ProcessingReport.DossierFiltersLoaded:
                setProcessingStep(ProcessingReport.DossierDefinitionLoad);
                break;

            case ProcessingReport.DossierDefinitionLoad:
                if (dossierDefinition.id !== '') {
                    setProcessingStep(ProcessingReport.DossierCreate);
                }
                else {
                    setMessage('Loading Report Definition');
                    setDossierDefinitionRequest({ ...cubeRequest, run: true });
                }
                break;

            case ProcessingReport.DosserDefinitionLoaded:
                const f: IDossierFilters[] = [];
                for (const d of dossierDefinition.chapters[0].filters) {
                    const match: IDossierFilters = getMatchingFilter(d);
                    if (match.id !== '') {
                        f.push(match);
                    }
                }
                setLocalDossierFilters(f);
                break;

            case ProcessingReport.DossierCreate:
                if (dossier) {
                    setProcessingStep(ProcessingReport.DossierFiltersApply);
                } else {
                    setMessage('Loading Report');
                    onCreateDossier();
                }
                break;

            case ProcessingReport.DossierFiltersApply:
                setMessage('Applying Filters to Report');
                applyDossierFilters();
                break;

            case ProcessingReport.Complete:
                debug && console.log('dossier', dossier);
                setMessage('');
                setInitialized(true);
                break;
        }
        // eslint-disable-next-line
    }, [processingStep]);

    useEffect(() => {
        if (!emptyGUID(report.id) && pageFilters.length > 2) {
            try {
                setFlag({
                    ...defaultReportFlag,
                    reportId: report.id,
                    report: report.title,
                    academicYear: pageFilters[0].values.filter(q => q.parentId === pageFilters[0].selection)[0].id,
                    academicYearLabel: pageFilters[0].values.filter(q => q.parentId === pageFilters[0].selection)[0].name,
                    districtId: pageFilters[1].values.filter(q => q.parentId === pageFilters[1].selection)[0].id,
                    district: pageFilters[1].values.filter(q => q.parentId === pageFilters[1].selection)[0].name,
                    schoolId: pageFilters[2].values.filter(q => q.parentId === pageFilters[2].selection)[0].id,
                    school: pageFilters[2].values.filter(q => q.parentId === pageFilters[2].selection)[0].name,
                });
            }
            catch { }
            // eslint-disable-next-line
        }
    }, [pageFilters, report]);

    useEffect(() => {
        if (groupType !== ReportGroupType.AboutPage && report.id !== '' && pageProcessingStep === ProcessingPage.Complete) {
            setProcessingStep(ProcessingReport.ReportLoad);
        }
        // eslint-disable-next-line
    }, [groupType]);

    useEffect(() => {
        let b: boolean = false;
        for (const o of exportOptions) {
            if (o.export) {
                const i: string[] = report.externalId.split(/\//);
                exportDossier(`https://${path.split(/\//)[2]}`, 'MicroStrategyLibrary', i[0], i[1], dossier.instanceId, o.filename, o.type.toString());
                o.export = false;
                b = true;
                setNotification({ message: `Export is processing....`, type: 'info' })
            }
        }
        if (b) {
            setExportOptions([...exportOptions]);
        }
        // eslint-disable-next-line
    }, [exportOptions]);

    useEffect(() => {
        if (reportService.result) {
            setCubeRequest({ ...getReportCubeRequest(reportService.result.report), tokenId: cubeRequest.tokenId });
            setReport(reportService.result.report);
            setPath(isPublic ? `${localStorage.getItem("msPublicPath")}/app` : reportService.result.reportServer.path);
            setPaginationLinks(getReportConfigurationValue(ReportConfigurationType.PaginationStyle, configurations.pageConfiguration, configurations.groupConfiguration) === ReportConfigurationPaginationStyles.Links);
            setReportSizing({
                minHeight: `${getReportConfigurationValue(ReportConfigurationType.MinReportHeight, reportService.result.report.configuration, configurations.groupConfiguration)}px`,
                maxHeight: `${getReportConfigurationValue(ReportConfigurationType.MaxReportHeight, reportService.result.report.configuration, configurations.groupConfiguration)}px`,
                minWidth: `${getReportConfigurationValue(ReportConfigurationType.MinReportWidth, reportService.result.report.configuration, configurations.groupConfiguration)}px`,
                maxWidth: `${getReportConfigurationValue(ReportConfigurationType.MaxReportWidth, reportService.result.report.configuration, configurations.groupConfiguration)}px`

            });
        }
        // eslint-disable-next-line
    }, [reportService]);

    useEffect(() => {
        if (processingStep === ProcessingReport.ReportLoading) {
            setProcessingStep(ProcessingReport.ReportLoaded);
        }
        // eslint-disable-next-line
    }, [report]);

    useEffect(() => {
        if (!dossierGetDefinitionService.isInProgress) {
            if (dossierGetDefinitionService.body) {
                setDossierDefinition(dossierGetDefinitionService.body);
            }
            if (dossierGetDefinitionService.error) {
                setNotification({ message: `${dossierGetDefinitionService.error}  (Report)`, type: 'error' })
            }
        }
        // eslint-disable-next-line
    }, [dossierGetDefinitionService]);

    useEffect(() => {
        if (processingStep === ProcessingReport.DossierDefinitionLoad) {
            setProcessingStep(ProcessingReport.DosserDefinitionLoaded);
        }
        // eslint-disable-next-line
    }, [dossierDefinition]);

    useEffect(() => {
        if (processingStep === ProcessingReport.DosserDefinitionLoaded) {
            setProcessingStep(ProcessingReport.DossierCreate);
            onUpdateHeight();
        }
        // eslint-disable-next-line
    }, [localDossierFilters]);

    useEffect(() => {
        onUpdateHeight();
        // eslint-disable-next-line
    }, [reportHeight, chapterList]);

    const onUpdateHeight = () => {
        const percent: number = getReportConfigurationValue(ReportConfigurationType.ReportHeightPercentage, configurations.reportConfiguration);
        if (percent === 100) {
            setDossierHeight(`calc(100vh - ${reportHeight + (dossierFilters.length > 0 ? 10 : 0) + (chapterList.totalPages > 1 ? 20 : 0)}px)`);
        } else {
            setDossierHeight(`calc(${percent}% - ${reportHeight}px)`);
        }
    }

    const getMatchingFilter = (d: IDossierDefinitionFilter): IDossierFilters => {
        // find in page filters
        for (const p of pageFilters) {
            if (p.key === d.source.id) {
                return {
                    id: p.key,
                    key: d.key,
                    selection: p.selection,
                    filterBy: true,
                    name: d.name,
                    pageFilter: true,
                    rangeIncrements: p.rangeIncrements
                };
            }
            const i: string[] = p.externalId.split(',');
            for (const s of i) {
                if (s.trim().toLowerCase() === d.name.trim().toLowerCase()) {
                    return {
                        id: d.source.id,
                        key: d.key,
                        selection: p.selection,
                        filterBy: true,
                        name: d.name,
                        pageFilter: true,
                        rangeIncrements: p.rangeIncrements
                    };
                }
            }
        }

        // find in dossier filters
        let match = dossierFilters.find(q => q.key === d.source.id);
        if (match) {
            return {
                id: match.key,
                key: d.key,
                selection: match.selection,
                filterBy: true,
                name: d.name,
                pageFilter: false,
                rangeIncrements: "0"
            };
        }

        match = dossierFilters.find(q => q.name === d.name);
        if (match) {
            return {
                id: match.key,
                key: d.key,
                selection: match.selection,
                filterBy: true,
                name: d.name,
                pageFilter: false,
                rangeIncrements: "0"
            };
        }
        return { id: '', key: '', selection: '', filterBy: false, name: '', pageFilter: false, rangeIncrements: "0" };
    }

    const onCreateDossier = () => {
        if (!emptyGUID(report.id) && path !== '') {
            const dossierCreate: IDossierCreation = {
                ...defaultDossierCreation,
                dossierChanged: onDossierChanged,
                debug,
                divId,
                errorHandler: onDossierError,
                url: `${path}/${report.externalId}`,
                settings: {
                    ...defaultDossierSettings,
                    showNavigationBar: getReportConfigurationValue(ReportConfigurationType.ShowNavgiationBar, configurations.reportConfiguration, report.reportConfiguration),
                    showFilters: getReportConfigurationValue(ReportConfigurationType.ShowFilters, configurations.reportConfiguration, report.reportConfiguration),
                    hideFilterSummary: getReportConfigurationValue(ReportConfigurationType.HideFilterSummary, configurations.reportConfiguration, report.reportConfiguration) || (pageSettings ? pageSettings.hideFilterSummary : true)
                },
                pageRendered: onDossierPageRendered,
                pageSwitched: onDossierPageSwitched,
            };

            dossierCreate.config.filters = [];
            for (const dossierFilter of localDossierFilters.filter(q => q.filterBy)) {
                if (!isSuppressionFilter(dossierFilter.name)) {
                    const creationFilter: IDossierCreationFilter = getDossierFilterSelectionForCreate(dossierFilter);
                    if (creationFilter.selections.length > 0) {
                        dossierCreate.config.filters.push(creationFilter);
                    }
                }
            }

            console.log('filters passed to dossier', dossierCreate);
            isPublic ? createPublicDossier(setDossier, dossierCreate) : createDossier(setDossier, { ...dossierCreate, baseUrl: `https://${path.split(/\//)[2]}` });
        }
    }

    useEffect(() => {
        if (dossier) {
            if (processingStep === ProcessingReport.DossierCreate) {
                getDossierChapterList(setChapterList, dossier);
                setProcessingStep(ProcessingReport.Complete);
                console.log(dossier);
            }
        }
        // eslint-disable-next-line
    }, [dossier]);

    const onDossierError = (e: any) => {
        reportLogToConsoleNotification(debug, setNotification, { message: `onDossierError  (Report)${e.message}`, type: 'error' });
    }

    const onDossierChanged = (e: any) => {
        reportLogToConsoleNotification(debug, setNotification, { message: `Instance ${e}`, type: 'info' });
    }

    const onDossierPageRendered = (e: any) => {
        reportLogToConsoleNotification(debug, setNotification, { message: `ON_PAGE_RENDER_FINISHED`, type: 'info' });
    }

    const onDossierPageSwitched = (e: any) => {
        reportLogToConsoleNotification(debug, setNotification, { message: `ON_PAGE_SWITCHED`, type: 'info' });
        onPageChanged();
    }

    const onPageChanged = () => {
        setProcessingStep(ProcessingReport.ReportLoaded);
    }

    const getFilterSelection = (selection: string, id: string): string => {
        try {
            if (selection.includes(id)) {
                return selection;
            }
            const i: string[] = selection.split(';');
            return `${i[0]};${id}`;
        }
        catch {
            return '';
        }
    }

    const getDossierFilterSelectionForCreate = (f: IDossierFilters): IDossierCreationFilter => {
        const v: IDossierCreationFilterSelection[] = [];
        let s: string = ''
        try {
            let match = pageFilters.find(q => q.key === f.id);
            if (!match) match = dossierFilters.find(q => q.name === f.name);
            if (match) {
                switch (match.filterType) {
                    case MicroStrategyDossierFilterType.MultipleSelect:
                        for (const c of match.selection.split(',')) {
                            s = getFilterSelection(c, f.id);
                            if (s !== '') { v.push({ id: s }); }
                        }
                        break;

                    default:
                        s = getFilterSelection(match.selection, f.id);
                        if (s !== '') { v.push({ id: s }); }
                        break;
                }
            }
        }
        catch (e) {
            setNotification({ message: `ERROR====>> getDossierFilterSelectionForCreate : ${e}`, type: 'error' })
        }

        return { key: f.key, selections: v };
    }

    const applyDossierFilters = () => {
        const filterList: IMicroStrategyDossierFilter[] = [];
        if (dossier) {
            for (const f of localDossierFilters.filter(q => q.filterBy)) {
                let i: IMicroStrategyDossierFilter;
                let match = pageFilters.find(q => q.key === f.id);
                if (!match) { match = dossierFilters.find(q => q.key === f.id); }
                if (match) { filterList.push(getDossierFilterSelectionForApply(f, match)); }
            }
            try {
                for (const f of filterList) {
                    try {
                        if (chapterIndex > 0) {
                            let match = dossierDefinition.chapters[chapterIndex].filters.filter(q => q.name === f.name);
                            if (match) {
                                switch (f.type) {
                                    case MicroStrategyDossierFilterType.Range:
                                        if (f.singleSelect) {
                                            f.singleSelect.filterInfo.key = match[0].key;
                                        }
                                        break;
                                    default:
                                        if (f.singleSelect) {
                                            f.singleSelect.filterInfo.key = match[0].key;
                                        }
                                        break;
                                }
                            }
                        }
                        switch (f.type) {
                            case MicroStrategyDossierFilterType.Range:
                                const start: number = f.start || 0;
                                const end: number = f.end || 0;
                                dossier.filterAttributeMultiSlider({
                                    filterInfo: { key: f.singleSelect?.filterInfo.key }, selections: [start < 0 ? 0 : start, end < 0 ? 2 : end]
                                });
                                break;

                            case MicroStrategyDossierFilterType.SingleSelectWithAll:
                            case MicroStrategyDossierFilterType.MultipleSelect:
                                dossier.filterSearchMultiAttributes(f.multipleSelect);
                                break;

                            default:
                                dossier.filterSelectSingleAttribute(f.singleSelect);
                                break;
                        }
                    }
                    catch (e) {
                        setNotification({ message: `ERROR====>> applyDossierFilters (setting filter ${f.name} : ${e}`, type: 'error' })
                    }
                }
                dossier.filterApplyAll();
            }
            catch (e) {
                setNotification({ message: `ERROR====>> applyDossierFilters : ${e}`, type: 'error' })
            }
            setProcessingStep(ProcessingReport.Complete);
        }
    };

    const getDossierFilterSelectionForApply = (f: IDossierFilters, rf: IMicroStrategyFilter): IMicroStrategyDossierFilter => {
        try {
            switch (rf.filterType) {
                case MicroStrategyDossierFilterType.Range:
                    const end: number = rf.values.findIndex(q => q.id === f.selection);
                    const start: number = end - (parseInt(f.rangeIncrements || "2", 10) || 2) + 1;
                    return {
                        start: start < 0 ? 0 : start,
                        end: end < 0 ? 0 : end,
                        singleSelect: { filterInfo: { key: f.key, }, holdSubmit: true, selection: { value: rf.selection } },
                        name: f.name,
                        type: rf.filterType,
                    }

                case MicroStrategyDossierFilterType.MultipleSelect:
                    const match: string[] = rf.selection.split(',');
                    const selections: IMicroStrategyDossierFilterSelection[] = [];
                    for (const m of match) {
                        selections.push({ value: m });
                    }
                    return {
                        multipleSelect: { selections, filterInfo: { key: f.key, }, holdSubmit: true },
                        name: f.name,
                        type: rf.filterType,
                    };

                case MicroStrategyDossierFilterType.SingleSelectWithAll:
                    const allSelections: IMicroStrategyDossierFilterSelection[] = [];
                    if (isSelectAll(rf.selection)) {
                        for (const m of rf.values) {
                            !isSelectAll(m.id) && allSelections.push({ value: m.id });
                        }
                    }
                    else {
                        allSelections.push({ value: rf.selection });
                    }
                    return {
                        multipleSelect: { selections: allSelections, filterInfo: { key: f.key, }, holdSubmit: true },
                        name: f.name,
                        type: rf.filterType,
                    };

                default:
                    if (isSuppressionFilter(f.name)) {
                        return {
                            singleSelect: {
                                filterInfo: { key: f.key, },
                                holdSubmit: true,
                                selection: { name: rf.selection === '1' || rf.selection.startsWith('h1') ? '1' : '0' }
                            },
                            name: f.name,
                            type: MicroStrategyDossierFilterType.SingleSelect
                        };
                    }

                    return {
                        singleSelect: { filterInfo: { key: f.key, }, holdSubmit: true, selection: { value: rf.selection } },
                        name: f.name,
                        type: MicroStrategyDossierFilterType.SingleSelect,
                    }
            }
        }
        catch {
            setNotification({ message: `Could not apply dossier filter ${rf.name} (Report)`, type: 'error' })
            return {
                singleSelect: { filterInfo: { key: '', }, holdSubmit: true, selection: { value: '' } },
                name: '', type: getFilterType(rf)
            }
        }
    }

    const getFilterType = (df: any): MicroStrategyDossierFilterType => {
        try {
            switch (df.filterType) {
                case 'attributeSlider':
                    return MicroStrategyDossierFilterType.Range;
                default:
                    return df.filterDetail.supportMultiple ? MicroStrategyDossierFilterType.MultipleSelect : MicroStrategyDossierFilterType.SingleSelect;
            }
        }
        catch {
            return MicroStrategyDossierFilterType.SingleSelect;
        }
    }

    const onExportReport = (i: IMicroStrategyExportOptions) => {
        exportOptions.filter(q => q.type === i.type)[0].export = true;
        setExportOptions([...exportOptions]);
    }

    const onShowDescription = () => {
        setShowDescription(true);
    }
    const onHideDescription = () => {
        setShowDescription(false);
    }

    const addDossierFilters = () => {
        // setProcessingStep(ProcessingReport.DossierFiltersInitialize);

    }

    return (
        <>
            {showDescription && (
                <ReportDescription title={report.title} description={report.description} onCancel={onHideDescription} />
            )}
            {processingStep === ProcessingReport.FatalError && (
                <OEMessage
                    className="report-errors"
                    hideDismissable={true}
                    message={error}
                    type={MessageType.Error}
                />
            )}
            {processingStep !== ProcessingReport.FatalError && (
                <>
                    <div className={`${paginationLinks ? 'report-title-links' : 'report-title'}`}>
                        <OERow>
                            <OECol sm={paginationLinks ? 4 : 12}>
                                {(multipleReports || (report.title)) && (
                                    <>
                                        {report.title}
                                        {report.description && report.description.length > 0 && (
                                            <OEIcon onClick={onShowDescription} className=" report-description-link m-l-5" icon={Icon.ReportDescriptionLink} />
                                        )}
                                        {flagging && (
                                            <ReportFlag item={flag} />
                                        )}
                                    </>
                                )}
                                {debug && (
                                    <small className="text-success pull-right">{`${processingStep} - ${debugMessage} (${chapterIndex} of ${chapterList.chapters.length})`}</small>
                                )}
                            </OECol>
                            {paginationLinks && (
                                <OECol className="text-right" sm={8}>
                                    <MicrostrategyPageLinks
                                        onUpdateFilters={addDossierFilters}
                                        chapterList={chapterList}
                                        dossier={dossier}
                                        setNotification={setNotification}
                                        onPageChanged={onPageChanged}
                                        aboutPages={aboutPages}
                                        reportID={report.id}
                                        aboutIndex={aboutIndex}
                                        setAboutIndex={setAboutIndex}
                                        setCurrentChapterIndex={setChapterIndex}
                                    />
                                </OECol>
                            )}
                        </OERow>
                        {exportOptions.map((i, index) =>
                            <ReportExport exportOption={i} key={index} exportReport={onExportReport} />
                        )}
                    </div>

                    {!paginationLinks && (
                        <MicrostrategyPageTabs
                            onUpdateFilters={addDossierFilters}
                            chapterList={chapterList}
                            dossier={dossier}
                            setNotification={setNotification}
                            onPageChanged={onPageChanged}
                            aboutPages={aboutPages}
                            reportID={report.id}
                            aboutIndex={aboutIndex}
                            setAboutIndex={setAboutIndex}
                            setCurrentChapterIndex={setChapterIndex}
                        />
                    )}

                    <DossierFilters
                        setNotification={setNotification}
                        reportProcessingStep={processingStep}
                        report={report}
                        setReportProcessingStep={setProcessingStep}
                        pageFilters={pageFilters}
                        setDossierFilters={setDossierFilters}
                        cubeAttributes={cubeAttributes}
                        setDebugMessage={setDebugMessage}
                        dossierDefinition={dossierDefinition}
                        chapterIndex={chapterIndex}
                    />

                    {aboutPages && aboutIndex >= 0 && (
                        <AboutSection group={aboutPages[aboutIndex]} />
                    )}

                    <OESpinner message={message} oeStyle={SpinnerStyle.Small} />
                    {groupType !== ReportGroupType.AboutPage && (
                        <div>
                            <div ref={exportReportRef} style={{
                                marginBottom: '1px',
                                marginTop: '5px',
                                height: dossierHeight,
                                minHeight: initialized ? reportSizing.minHeight : 0,
                                maxHeight: initialized ? reportSizing.maxHeight : 0,
                                minWidth: initialized ? reportSizing.minWidth : 0,
                                maxWidth: initialized ? reportSizing.maxWidth : 0
                            }}>
                                <div className={`${initialized ? 'three-tier-open' : 'three-tier-closed'}`} id={divId} />
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default Report;
