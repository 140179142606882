import React, { Fragment, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Icon } from '../../entities/Icon';
import { formatCurrency, formatDecimal, formatInteger, formatPercent } from '../../utilities/String';
import OEButton, { ButtonStyle } from '../form/OEButton';
import { OEFormControl } from '../form/OEForm';
import OEIcon from '../general/OEIcon';
import { OECol } from '../grid/OECol';
import { OERow } from '../grid/OERow';
import { MessageType } from '../messaging/enums/InformationMessages';
import OEMessage from '../messaging/OEMessage';
import OESpinner from '../messaging/OESpinner';
import { ColumnType, getColumnStyle, IColumn } from './entities/Column';
import { showColumnAction } from './entities/ColumnAction';
import { IPagination, Pagination } from './entities/Pagination';
import { ITableAction } from './entities/TableAction';
import OEColumn from './OEColumn';
import OEColumnActions from './OEColumnActions';
import OEPagination from './OEPagination';
import OETableExport from './OETableExport';


const MaxPageSize: number = 0;

const isGreater = (type: string, a: any, b: any) => {
    // tslint:disable
    switch (type) {
        case ColumnType.String:
        case ColumnType.Link:
            const a1 = (a && a.toString) ? a.toString() : '';
            const b1 = (b && b.toString) ? b.toString() : '';
            return a1.toLowerCase() > b1.toLowerCase();

        case ColumnType.Integer:
        case ColumnType.ID:
            return parseInt(a, 10) > parseInt(b, 10);

        case ColumnType.Decimal:
            return parseFloat(a) > parseFloat(b);

        default:
            return a > b;
    }
    // tslint:enable
};

const hasSubTotals = (columns: IColumn[]): boolean => {
    for (const c of columns) {
        if (c.subTotal) {
            return true;
        }
    }
    return false;
};

const getSubTotal = (data: any[], name: string, type: string, percision?: number,): string => {
    let total: number = 0;
    for (const c of data) {
        total = total + parseFloat(c[name]);
    }
    // tslint:disable
    switch (type) {
        case ColumnType.Integer:
            return formatInteger(total);

        case ColumnType.Decimal:
            return formatDecimal(total, percision || 2);

        case ColumnType.Currency:
            return formatCurrency(total, percision || 2);

        case ColumnType.Percentage:
            return formatPercent(total / 100, percision || 0);

        case ColumnType.PercentageExact:
            return formatPercent(total, percision || 0);

        default:
            return total.toString();
    }
    // tslint:enable
};

interface IComponentInfo {
    columns: IColumn[];
    data: any[];
    showPagination?: boolean;
    pageSizes?: number[];
    defaultPageSize?: number;
    includeRowNumber?: boolean;
    defaultSort?: string;
    noDataMessage?: string;
    hideTable?: boolean;
    hideHeader?: boolean;
    showPageSize?: boolean;
    showPageTotals?: boolean;
    startPage?: number;
    width?: string;
    onSelectAll?: (start: number, end: number, checked: boolean) => void;
    onPaginationUpdate?: (pagination: IPagination) => void;
    striped?: boolean;
    bordered?: boolean;
    hover?: boolean;
    notResponsive?: boolean;
    className?: string;
    actions?: ITableAction[];
    loading?: boolean;
    loadingMessage?: string;
    showExport?: boolean;
    exportFilename?: string;
    recordCount?: number;
    onPageChanged?: (start: number, current: number, pageSize: any) => void;
}

const OETable: React.FunctionComponent<IComponentInfo> = ({ columns, data, showPagination, pageSizes
    , defaultPageSize, includeRowNumber, defaultSort, noDataMessage, hideTable, hideHeader
    , showPageSize, showPageTotals, width, onSelectAll, startPage, onPaginationUpdate
    , striped, bordered, hover, notResponsive, className, actions, loading, loadingMessage
    , showExport, exportFilename, recordCount, onPageChanged
}) => {

    const [pageStart, setStart] = useState(1);
    const [pageEnd, setEnd] = useState(showPagination ? 0 : MaxPageSize);
    const [columnData, setColumnData] = useState(data);
    const [sortColumn, setSortColumn] = useState(defaultSort);
    const [sortOrder, setSortOrder] = useState('asc');
    const [exportFile, setExportFile] = useState<boolean>(false);

    useEffect(() => {
        setColumnData(data);
    }, [data]);

    const [pagination, setPagination] = useState<IPagination>(new Pagination
        (
            0, defaultPageSize || 25, 1, pageSizes || [10, 25, 50, 100, 250, 500]
            , showPageSize, showPageTotals
        )
    );

    useEffect(() => {
        if (pagination) {
            setStart(pagination.start || 1);
            setEnd(pagination.end || MaxPageSize);
        }
        onPaginationUpdate && onPaginationUpdate(pagination);
    }, [pagination, onPaginationUpdate]);

    useEffect(() => {
        if (data) {
            setPagination(new Pagination(recordCount ? recordCount : data.length, pagination.pagesize, startPage ? startPage : 1, pagination.pagesizeoptions, showPageSize, showPageTotals, pagination.pagelistsize));
            setStart(pagination.start || 1);
            setEnd(pagination.end || MaxPageSize);
        }
        // eslint-disable-next-line
    }, [data]);

    const noDataDefaultMessage = 'No data found.';
    const rowColumn: IColumn = {
        id: 'OERowColumn', name: 'OERowColumn', type: ColumnType.RowNumber, width: '60px', sort: true
    };

    const onChangePagination = (s: number, e: number, c: number, t: any) => {
        setStart(s);
        setEnd(e);
        if(onPageChanged && s < pagination.records){
            onPageChanged(s, c, t > pagination.pagesize ? t : null);
        }
    };

    const onSelectAllChange = (event: any) => {
        onSelectAll && onSelectAll(pagination.start, pagination.end, event.target.checked);
    };

    const getSortIcon = (column: string) => {
        if (sortColumn !== column || sortOrder === '') return Icon.TableSort;
        return sortOrder === 'desc' ? Icon.TableSortDesc : Icon.TableSortAsc;
    };

    const onSort = (event: any) => {
        let index = parseInt(event.target.id.replace('fa', ''), 10);
        if (!(index >= 0)) index = parseInt(event.target.parentElement.id.replace('fa', ''), 10);
        if (!(index >= 0)) index = parseInt(event.target.parentElement.parentElement.id.replace('fa', ''), 10);
        const item: IColumn = columns[index];
        if (item.sort) {
            let order = 'asc';
            const idColumn = item.sortColumn ? item.sortColumn : item.id;
            if (sortColumn !== item.id) {
                setSortColumn(item.id);
                setSortOrder(order);

                setColumnData(columnData.sort((a, b) => {
                    // tslint:disable
                    switch (item.type) {
                        case ColumnType.Boolean:
                        case ColumnType.Actions:
                            return (a[idColumn] === b[idColumn]) ? 0 : a[idColumn] ? -1 : 1;

                        default:
                            if (isGreater(item.type, a[idColumn], b[idColumn])) { return 1; }
                            if (isGreater(item.type, b[idColumn], a[idColumn])) { return -1; }
                            return 0;
                    }
                    // tslint:enable
                }));
            }
            else {
                order = sortOrder === 'asc' ? 'desc' : 'asc';
                setColumnData(columnData.reverse());
            }

            setSortOrder(order);
        }
    };

    const onExportCancel = () => {
        setExportFile(false);
    };

    const onExport = () => {
        setExportFile(true);
    };

    return (
        <>
            {loadingMessage && (
                <OESpinner message={loadingMessage} hidden={!loading} />
            )}
            {!hideTable && !loading && (
                <>
                    {columnData.length > 0 &&
                        <Table
                            className={className}
                            responsive={!notResponsive}
                            bordered={bordered}
                            striped={striped === undefined ? true : striped}
                            hover={hover === undefined ? true : hover}
                        >
                            {!hideHeader && (
                                <thead>
                                    <tr>
                                        {includeRowNumber && (
                                            <th className="text-nowrap" style={getColumnStyle(rowColumn)}>#</th>
                                        )}
                                        {columns.map((item, index) =>
                                            <Fragment key={`action${index}`}>
                                                {(
                                                    !item.condition
                                                    || (!item.notCondition && columnData[0][item.condition])
                                                    || (item.notCondition && !columnData[0][item.condition])
                                                ) && (
                                                        <>
                                                            {item.type === ColumnType.Checkbox && (
                                                                <th className="text-center" >
                                                                    <OEFormControl id="checkboxSelectAll" type="checkbox" value={data} onChange={onSelectAllChange} />
                                                                </th>
                                                            )}
                                                            {item.type !== ColumnType.Checkbox && (
                                                                <>
                                                                    {item.sort && (
                                                                        <th style={getColumnStyle(item)} onClick={onSort} id={index.toString()} className={`${item.className || (item.type === ColumnType.Check ? 'text-center' : '')} text-nowrap`}>
                                                                            {item.nameIcon && (
                                                                                <OEIcon title={item.name || item.id} icon={item.nameIcon} />
                                                                            )}
                                                                            {!item.nameIcon && (
                                                                                <Fragment>
                                                                                    {item.name || item.id}
                                                                                </Fragment>
                                                                            )}
                                                                            {item.sort && getSortIcon(item.id) === Icon.TableSort && (
                                                                                <span id={`fa${index.toString()}`}>
                                                                                    <span className={Icon.TableSort} />
                                                                                </span>
                                                                            )}
                                                                            {item.sort && getSortIcon(item.id) === Icon.TableSortAsc && (
                                                                                <span id={`fa${index.toString()}`}>
                                                                                    <span className={Icon.TableSortAsc} />
                                                                                </span>
                                                                            )}
                                                                            {item.sort && getSortIcon(item.id) === Icon.TableSortDesc && (
                                                                                <span id={`fa${index.toString()}`}>
                                                                                    <span className={Icon.TableSortDesc} />
                                                                                </span>
                                                                            )}
                                                                        </th>
                                                                    )}
                                                                    {!item.sort && (
                                                                        <th style={getColumnStyle(item)} id={`fa${index.toString()}`} className={item.className}>
                                                                            {item.nameIcon && (
                                                                                <OEIcon title={item.name} icon={item.nameIcon} />
                                                                            )}
                                                                            {!item.nameIcon && (
                                                                                <>
                                                                                    {item.name || item.id}
                                                                                </>
                                                                            )}
                                                                        </th>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                            </Fragment>
                                        )}
                                    </tr>
                                    {columns.filter(q => q.filter).length > 0 && (
                                        <>
                                            {includeRowNumber && (
                                                <td />
                                            )}
                                            {columns.map((item, index) =>
                                                <Fragment key={`action${index}`}>
                                                    {item.filter && (
                                                        <td>
                                                            <OEFormControl type="text" placeholder={`filter by ${item.name || item.id}`} />
                                                        </td>
                                                    )}
                                                    {!item.filter && (
                                                        <td />
                                                    )}
                                                </Fragment>
                                            )}
                                        </>
                                    )}
                                </thead>
                            )}
                            <tbody>
                                {columnData.map((item, index) =>
                                    <Fragment key={index}>  
                                        {(!showPagination || (startPage || (index + 1 >= pageStart && index + 1 <= pageEnd))) && (
                                            <tr>
                                                {includeRowNumber && (
                                                    <OEColumn column={rowColumn} index={index} data={`${index + (recordCount ? pageStart : 1)}.`} />
                                                )}
                                                {
                                                    columns.map((item2, index2) =>
                                                        <Fragment key={index2}>
                                                            {(
                                                                !item2.condition
                                                                || (!item2.notCondition && item[item2.condition])
                                                                || (item2.notCondition && !item[item2.condition])
                                                            ) && (
                                                                    <>
                                                                        {item2.type !== ColumnType.Actions && (
                                                                            <OEColumn column={item2}
                                                                                timezone={item2.timezone ? item[item2.timezone] : undefined}
                                                                                data2={item2.idNewLine ? item[item2.idNewLine] : undefined}
                                                                                data={item[item2.id]} index={index} item={item} />
                                                                        )}
                                                                        {item2.type === ColumnType.Actions && (
                                                                            <td style={getColumnStyle(item2)} className={`${item2.className}  text-nowrap`}>
                                                                                {item2.actions && item2.actions.map((item3, i) =>
                                                                                    <Fragment key={i}>
                                                                                        {(showColumnAction(item3, item)) && (
                                                                                            <>
                                                                                                <OEColumnActions
                                                                                                    item={item}
                                                                                                    text={item3.text}
                                                                                                    icon={item3.icon}
                                                                                                    onClick={item3.onClick}
                                                                                                    className={item3.className}
                                                                                                    helpText={item3.helpText}
                                                                                                    href={item3.href}
                                                                                                    download={item3.download}
                                                                                                    target={item3.target}
                                                                                                />
                                                                                            </>
                                                                                        )}
                                                                                    </Fragment>
                                                                                )}
                                                                            </td>
                                                                        )}
                                                                    </>
                                                                )}
                                                        </Fragment>
                                                    )
                                                }
                                            </tr>
                                        )}
                                    </Fragment>
                                )}
                            </tbody>

                            {hasSubTotals(columns) && (
                                <tfoot><tr>
                                    {includeRowNumber && (
                                        <td />
                                    )}
                                    {columns.map((item, index) =>
                                        <Fragment key={index}>
                                            {item.subTotal && (
                                                <td className={item.className}><strong>{`${item.subTotalPrefix ? `${item.subTotalPrefix}:` : ''} ${getSubTotal(columnData, item.id, item.type, item.percision)}`}</strong></td>
                                            )}
                                            {!item.subTotal && (
                                                <td />
                                            )}
                                        </Fragment>
                                    )}
                                </tr></tfoot>
                            )}
                        </Table>}
                    {columnData.length === 0 && noDataMessage !== '' && (
                        <div className={`${className || 'm-t-40'}`}>
                            <OEMessage
                                style={{ marginLeft: '20px', marginRight: '20px', marginBottom: '40px' }}

                                icon={Icon.Info}
                                type={MessageType.Light}
                                hideDismissable={true}
                                message={noDataMessage ? noDataMessage : noDataDefaultMessage}
                            />
                        </div>
                    )}
                    {columnData.length > 0 && showPagination && (
                        <OERow>
                            <OECol sm={12}>
                                {columnData.length > 0 && showPagination && (
                                    <OEPagination {...pagination} onChange={onChangePagination} setPagination={setPagination} />
                                )}
                            </OECol>
                        </OERow>
                    )}

                    {actions && actions.map((item, index) =>
                        <OEButton
                            key={index}
                            hidden={item.hidden}
                            icon={item.icon}
                            text={item.text}
                            onClick={item.action}
                            bStyle={ButtonStyle.Light}
                            className="m-t-10 m-b-10 pull-right btn-sm btn-dark"
                        />
                    )}
                    {columnData.length > 0 && showExport && (
                        <>
                            <OEButton className="m-t-10 m-b-10 pull-right btn-sm btn-dark" onClick={onExport} text="Export to CSV" />
                            {showExport && (
                                <OETableExport columns={columns} data={data} filename={exportFilename} onCancel={onExportCancel} showExport={exportFile} />
                            )}
                        </>

                    )}
                    <div className="cleardiv" />
                </>
            )}
        </>
    );
};

export default OETable;