import React, { Fragment, useEffect, useRef, useState } from 'react';
import OEMessage from '../../../../core/components/messaging/OEMessage';
import OENotification from '../../../../core/components/messaging/OENotification';
import { INotification, defaultNotification } from '../../../../core/components/messaging/entities/Notification';
import { MessageType } from '../../../../core/components/messaging/enums/InformationMessages';
import { displayHTMLContent } from '../../../../core/utilities/Miscellaneous';
import { parseQueryString, updateURLParameter } from '../../../../core/utilities/URL';
import { canDebugReport } from '../../../../reporting/entities/Report';
import { IReportConfigurations, ReportConfigurationGroupStyles, ReportConfigurationType, defaultReportConfigurations, getReportConfigurationValue } from '../../../../reporting/entities/ReportConfiguration';
import { IReportGroup, ReportGroupType, defaultReportGroup } from '../../../../reporting/entities/ReportGroup';
import { IReportPage, defaultReportPage } from '../../../../reporting/entities/ReportPage';
import { ProcessingPage, logProcessingPage } from '../../../../reporting/entities/ReportProcessing';
import { useGetReportPageTiered } from '../../../../reporting/services/ReportPageService';
import { IDossierSettings, defaultDossierSettings } from '../../../entities/MicroStrategyDossier';
import { IMicroStrategyFilter } from '../../../entities/MicroStrategyFilters';
import PageFilters from '../../common/PageFilters';
import PageTemplate from '../../common/PageTemplate';
import Report from '../../common/Report';
import AboutSection from '../../report-common/AboutSection';
import SectionGroupList from './SectionGroupList';
import SectionTitle from './SectionTitle';

declare global { var runCode: any; }
declare global { var microstrategy: any; }

const MSEmbeddedTierThreeTest: React.FunctionComponent = () => {
    const params: any = parseQueryString();
    const [pageParameterId] = useState(params['pid'] || '');
    const headerRef = useRef<any>();
    const footerRef = useRef<any>();
    const reportRef = useRef<any>();

    const { service: pageService, setPageId } = useGetReportPageTiered('');

    const [debug] = useState<boolean>(canDebugReport);
    const [processingStep, setProcessingStep] = useState<ProcessingPage>(ProcessingPage.Idle);
    const [pageFilters, setPageFilters] = useState<IMicroStrategyFilter[]>([]);
    const [reportId, setReportId] = useState<string>('');
    const [notiication, setNotification] = useState<INotification>(defaultNotification);

    const [page, setPage] = useState<IReportPage>(defaultReportPage);
    const [pageSettings, setPageSettings] = useState<IDossierSettings>(defaultDossierSettings);
    const [section, setSection] = useState<IReportGroup>(defaultReportGroup);
    const [defaultSection, setDefaultSection] = useState<IReportGroup>(defaultReportGroup);
    const [group, setGroup] = useState<IReportGroup>(defaultReportGroup);
    const [selectedGroup, setSelectedGroup] = useState<string>('');
    const [error, setError] = useState<string>('');

    const [footer, setFooter] = useState<string>('');
    const [reportHeight, setReportHeight] = useState<number>(0);
    const [navHeight, setNavHeight] = useState<number>(0);
    const [configurations, setConfigurations] = useState<IReportConfigurations>(defaultReportConfigurations);

    useEffect(() => {
        window.onresize = onWindowResized;
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        logProcessingPage(processingStep, debug);
        if (processingStep !== ProcessingPage.FatalError) {
            setError('');
        }
        switch (processingStep) {
            case ProcessingPage.PageLoad:
                setPageId(pageParameterId);
                break;

            case ProcessingPage.PageLoaded:
                try {
                    setReportId(page.groups[0].groups[0].groups[0].reports[0].reportId);
                    setProcessingStep(ProcessingPage.SectionLoad);
                }
                catch {
                    setProcessingStep(ProcessingPage.FatalError);
                    setError(`Cube could not be setup based on the first report for this page.`);
                }
                break;


            case ProcessingPage.SectionLoad:
                setReportId('');
                if (!setSectionByID(params.sid)) {
                    onChangeSectionTitle(page.groups.filter(q => q.isActive)[0]);
                }
                break;

            case ProcessingPage.SectionLoaded:
                updateURLParameter("sid", section.id);
                setProcessingStep(ProcessingPage.GroupLoadInitial);
                break;

            case ProcessingPage.GroupLoadInitial:
                if (!setGroupByID(params.gid)) {
                    setGroup(section.groups.filter(q => q.isActive)[0]);
                }
                break;

            case ProcessingPage.GroupLoad:
                loadGroup();
                break;

            case ProcessingPage.GroupLoaded:
                if (group.groupType === ReportGroupType.AboutPage) {
                    setProcessingStep(ProcessingPage.Complete);
                }
                else {
                    setReportId('');
                    setProcessingStep(ProcessingPage.ReportLoad);
                    onUpdateHeight();
                }
                break;

            case ProcessingPage.ReportLoad:
                if (group.reports.length === 0) {
                    setProcessingStep(ProcessingPage.FatalError);
                    setError(`There are no reports setup for the report group: <b><i> ${group.title} </b></i> in the section <b><i> ${section.title}</b></i>`);
                } else {
                    setReportId(group.reports[0].reportId);
                }
                break;

            case ProcessingPage.ReportLoaded:
                break;

            case ProcessingPage.Complete:
                break;
        }
        // eslint-disable-next-line
    }, [processingStep]);

    useEffect(() => {
        if (pageService.result) {
            setPage(pageService.result);
        }
        // eslint-disable-next-line
    }, [pageService]);

    useEffect(() => {
        if (page.id !== '') {
            setProcessingStep(ProcessingPage.PageLoaded);
            setConfigurations({ ...configurations, pageConfiguration: page.configuration });
        }
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        if (section.id !== '') {
            //            updateProcessingStep(ReportProcessingStep.Finished ? ReportProcessingStep.LoadSection : ReportProcessingStep.SectionLoaded, setProcessingStep);
            setProcessingStep(ProcessingPage.SectionLoaded);
            setConfigurations({ ...configurations, sectionConfiguration: section.configuration });
        }
        // eslint-disable-next-line
    }, [section]);

    useEffect(() => {
        if (group.id !== '') {
            if (!getReportConfigurationValue(ReportConfigurationType.MultipleReports, group.configuration)) {
                if (group.reports.length > 1) {
                    group.reports = [{ ...group.reports[0] }];
                }
            }
            setProcessingStep(ProcessingPage.GroupLoad);
            setConfigurations({ ...configurations, groupConfiguration: group.configuration });
        }
        // eslint-disable-next-line
    }, [group]);

    const loadGroup = () => {
        updateURLParameter('gid', group.id);
        if (group.reports.length > 0) {
            setFooter(getReportConfigurationValue(ReportConfigurationType.ReportFoooter, configurations.groupConfiguration));
        }
        setPageSettings({
            ...defaultDossierSettings,
            hideFilterSummary:
                getReportConfigurationValue(ReportConfigurationType.HideFilterSummary, configurations.pageConfiguration)
                || getReportConfigurationValue(ReportConfigurationType.HideFilterSummary, configurations.sectionConfiguration)
                || getReportConfigurationValue(ReportConfigurationType.HideFilterSummary, configurations.groupConfiguration),
            collapsibleSections: getReportConfigurationValue(ReportConfigurationType.CollapsibleSections, configurations.pageConfiguration)
        });
        setProcessingStep(ProcessingPage.GroupLoaded);
    }

    const onWindowResized = () => {
        onUpdateHeight();
    }

    const onUpdateHeight = () => {
        if (headerRef.current) {
            let h: number =
                headerRef.current.getBoundingClientRect().height
                + (footerRef.current ? footerRef.current.getBoundingClientRect().height : 16)
                + headerRef.current.offsetTop + 55
                ;

            // subrtract height of tabs
            if (getReportConfigurationValue(ReportConfigurationType.GroupStyle, configurations.sectionConfiguration) === ReportConfigurationGroupStyles.Tabs) {
                h = h - 40;
            }

            // subtract height of report title
            if (group.reports.length > 1) {
                h = h - 20;
            }
            setReportHeight(h);
            setNavHeight(headerRef.current.offsetTop + 60);
            //    setNotification({ duration: 10000, message: `report top ${headerRef.current.getBoundingClientRect().height}, 
            //    footer ${footerRef.current ? footerRef.current.getBoundingClientRect().height : 0}, header ${headerRef.current.offsetTop}, totalHeight ${h}`, type: 'info' });
        }
    }

    const setSectionByID = (id: string): boolean => {
        for (const sh of page.groups.filter(q => q.isActive)) {
            for (const sl of sh.groups.filter(q => q.isActive)) {
                if (getReportConfigurationValue(ReportConfigurationType.DefaultSection, sl.configuration)) {
                    setDefaultSection(sl);
                }
                if (sl.id === id) {
                    setSection(sl);
                    setSelectedGroup(sh.id);
                    return true;
                }
            }
        }
        return false;
    }

    const setGroupByID = (id: string): boolean => {
        for (const g of section.groups) {
            if (g.id === id) {
                setGroup(g);
                return true;
            }
        }
        return false;
    }

    const onChangeSection = (i: IReportGroup) => {
        updateURLParameter('gid', '');
        setSection(i);
    }

    const onChangeSectionTitle = (i: IReportGroup) => {
        if (selectedGroup !== i.id) {
            for (const c of i.groups.filter(q => q.isActive)) {
                if (getReportConfigurationValue(ReportConfigurationType.DefaultSection, c.configuration)) {
                    setDefaultSection(c);
                    break;
                }
            }
            setSelectedGroup(i.id);
            setSection(i.groups.filter(q => q.isActive)[0]);
        }
        else {
            for (const c of i.groups.filter(q => q.isActive)) {
                if (getReportConfigurationValue(ReportConfigurationType.DefaultSection, c.configuration)) {
                    setSection(c);
                    break;
                }
            }
        }
    }
    const onChangeGroup = (i: IReportGroup) => {
        setGroup(i);
    }

    return (
        <div className="report-embedded m-b-0">
            <OENotification setNotification={setNotification} notification={notiication} />
            {debug && (
                <h5 className="text-success" style={{ position: 'absolute', top: '26px', left: '340px' }} >Page: {processingStep}</h5>
            )}

            <PageTemplate
                setNotification={setNotification}
                setProcessingStep={setProcessingStep}
                processingStep={processingStep}
            />
            {pageParameterId && pageParameterId !== '' && (
                <>
                    <p className="title m-t-10 m-b-5 m-l-10">{`${page.name}`} </p>
                    <div className="three-tier">
                        <div style={{ height: `${pageSettings.collapsibleSections ? `calc(100vh - ${navHeight + 20}px)` : '100%'}` }} className="left-nav">
                            {page.groups.filter(q => q.isActive).map((item, index) =>
                                <Fragment key={index} >
                                    <SectionTitle
                                        onClick={onChangeSectionTitle}
                                        onChangeSection={onChangeSection}
                                        selected={item.id === selectedGroup}
                                        defaultSection={defaultSection}
                                        selectedSection={section.id}
                                        item={item}
                                        collapsible={pageSettings.collapsibleSections}
                                    />
                                </Fragment>
                            )}
                        </div>

                        <div className="content">
                            <div ref={headerRef} >
                                <PageFilters
                                    setNotification={setNotification}
                                    pageProcessingStep={processingStep}
                                    reportId={reportId}
                                    page={page}
                                    setPageProcessingStep={setProcessingStep}
                                    setPageFilters={setPageFilters}
                                />
                                {processingStep !== ProcessingPage.FatalError && section.groups.filter(q => q.isActive).length > 1 && (
                                    <SectionGroupList section={section} activeGroup={group} setActiveGroup={onChangeGroup} />
                                )}

                            </div>
                            {processingStep === ProcessingPage.FatalError && (
                                <OEMessage
                                    className="report-errors"
                                    hideDismissable={true}
                                    message={error}
                                    type={MessageType.Error}
                                />
                            )}
                            {processingStep !== ProcessingPage.FatalError && reportId !== '' && (
                                <div ref={reportRef} className="report-title">
                                    {group.id !== '' && (
                                        <>
                                            {group.groupType === ReportGroupType.AboutPage && (
                                                <AboutSection group={group} />
                                            )}
                                            {group.reports.filter(q => q.isActive).map((item4, index4) =>
                                                <Report
                                                    key={index4}
                                                    reportId={item4.reportId}
                                                    setNotification={setNotification}
                                                    pageFilters={pageFilters}
                                                    pageProcessingStep={processingStep}
                                                    divId={`report${index4}`}
                                                    configurations={{ ...configurations, reportConfiguration: item4.configuration }}
                                                    pageSettings={pageSettings}
                                                    reportHeight={reportHeight}
                                                    groupType={group.groupType}
                                                    index={index4}
                                                    multipleReports={getReportConfigurationValue(ReportConfigurationType.MultipleReports, configurations.groupConfiguration)}
                                                />

                                            )}

                                            {footer && (
                                                <div className="footer" ref={footerRef} dangerouslySetInnerHTML={displayHTMLContent(footer)} />
                                            )}
                                        </>
                                    )}
                                    {section.groups.length === 0 && section.id !== '' && (
                                        <OEMessage className="h5" type={MessageType.Danger} hideDismissable={true} message={`No groups have been set up for ${section.title} `} />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div >
    );
};

export default MSEmbeddedTierThreeTest;
