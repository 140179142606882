import React, { useEffect, useState } from 'react';
import { OECol } from '../../../core/components/grid/OECol';
import { OERow } from '../../../core/components/grid/OERow';
import { ISortData } from '../../../core/components/sort/entities/SortData';
import OESort from '../../../core/components/sort/OESort';
import { ColumnType, IColumn } from '../../../core/components/table/entities/Column';
import { IColumnAction } from '../../../core/components/table/entities/ColumnAction';
import OETable from '../../../core/components/table/OETable';
import { Icon } from '../../../core/entities/Icon';
import { defaultReportBaseFilter, getJSONForReportFilterSave, getReportBaseFilterFromJSON, IReportBaseFilter, IReportFilter } from '../../../report-microstrategy/entities/ReportFilter';
import FilterFormik from './FilterFormik';
import ReportFilterFormik from './ReportFilterFormik';

enum ModalTypes {
    None = 1,
    Edit,
    Add,
    Sort
}

interface IFiltersInfo {
    label?: string;
    name?: string;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    values: any;
    filters: IReportFilter[];
    isReport: boolean;
}

const Filters: React.FunctionComponent<IFiltersInfo> = ({ label, name, setFieldValue, values, isReport }) => {
    const [showModal, setShowModal] = useState<ModalTypes>(ModalTypes.None);
    const [items, setItems] = useState<IReportBaseFilter[]>([]);
    const [item, setItem] = useState<IReportBaseFilter>(defaultReportBaseFilter);
    const [sortList, setSortList] = useState<ISortData[]>([]);

    const onDeleteFilter = (i: IReportFilter) => {
        setFieldValue("filters", getJSONForReportFilterSave(items.filter(q => q.name !== i.name)));
    }


    useEffect(() => {
        const f: IReportBaseFilter[] = getReportBaseFilterFromJSON(values.filters);
        if (JSON.stringify(items) !== JSON.stringify(f)) {
            setItems(f);
        }
        // eslint-disable-next-line
    }, [values.filters]);

    const onCancel = () => {
        setShowModal(ModalTypes.None);
    };

    const onSubmit = (i: IReportBaseFilter) => {
        if (items.filter(q => q.name === i.name).length > 0) {
            const c: IReportBaseFilter = items.filter(q => q.name === i.name)[0];
            c.name = i.name;
            c.label = i.label;
            c.sortOrder = i.sortOrder;
            c.externalId = i.externalId;
            c.showSelectAll = i.showSelectAll;
            c.selectAllText = i.selectAllText;
            c.overrideValue = i.overrideValue;
            c.msId = i.msId;
            c.dossier = i.dossier;
            c.rangeIncrements = i.rangeIncrements;
            c.filterAlignment = i.filterAlignment;
            c.ignoreFilter = i.ignoreFilter;
            c.noFilterText = i.noFilterText;
            c.defaultSelection = i.defaultSelection;
            c.cascadePriority = i.cascadePriority;
            c.cubeId = i.cubeId;
            c.mulitipleSelect = i.mulitipleSelect;
            c.rawValueSortIndex = i.rawValueSortIndex;
            c.selectAllLabel = i.selectAllLabel;
            c.filterType = i.filterType;
        }
        else {
            items.push({
                id: i.id,
                name: i.name,
                label: i.label,
                sortOrder: items.length + 1,
                cascading: i.cascading,
                externalId: i.externalId,
                showSelectAll: i.showSelectAll,
                returnValues: i.returnValues,
                overrideValue: i.overrideValue,
                selectAllText: i.selectAllText,
                dossier: i.dossier,
                msId: i.msId,
                msName: i.msName,
                rangeIncrements: i.rangeIncrements,
                filterAlignment: i.filterAlignment,
                ignoreFilter: i.ignoreFilter,
                noFilterText: i.noFilterText,
                defaultSelection: i.defaultSelection,
                cascadePriority: i.cascadePriority || 99,
                cubeId: '',
                mulitipleSelect: i.mulitipleSelect,
                filterLoaded: false,
                rawValueSortIndex: i.rawValueSortIndex,
                selectAllLabel: i.selectAllLabel,
                filterType: i.filterType
            })
        }
        setFieldValue("filters", getJSONForReportFilterSave(items));
        setShowModal(ModalTypes.None);
    };

    const onAddFilter = () => {
        setShowModal(ModalTypes.Add);
        setItem(defaultReportBaseFilter);
    };

    const onAddDossierFilter = () => {
        setShowModal(ModalTypes.Add);
        setItem({ ...defaultReportBaseFilter, dossier: true });
    };

    const onEditFilter = (i: IReportBaseFilter) => {
        setShowModal(ModalTypes.Edit);
        setItem(i);
    };

    const actions: IColumnAction[] = [
        { icon: Icon.Edit, onClick: onEditFilter, helpText: 'Edit' },
        { icon: Icon.Delete, onClick: onDeleteFilter, helpText: 'Delete' },
    ];

    const columns: IColumn[] = [
        { actions, id: '', name: '', sort: false, type: ColumnType.Actions, width: '20px' },
        { id: 'name', name: 'Name', sort: false, type: ColumnType.String },
        { id: 'label', name: 'Label', sort: false, type: ColumnType.String },
        { id: 'sortOrder', name: 'Order', sort: false, type: ColumnType.Integer, className: 'text-center' },
    ];

    const reportColumns: IColumn[] = [
        ...columns,
        { id: 'showSelectAll', name: 'All?', sort: false, type: ColumnType.Boolean, className: 'text-center', condition: 'dossier', notCondition: true },
        { id: 'N/A', name: 'All?', sort: false, type: ColumnType.Text, className: 'text-center', condition: 'dossier' },
        { id: 'overrideValue', name: 'Override', sort: false, type: ColumnType.String, className: 'text-center', condition: 'dossier', notCondition: true },
        { id: 'N/A', name: 'Override', sort: false, type: ColumnType.Text, className: 'text-center', condition: 'dossier' },
        { id: 'dossier', name: 'Dossier', sort: false, type: ColumnType.Boolean, className: 'text-center' },
        { id: 'ignoreFilter', name: 'Ignore', sort: false, type: ColumnType.Boolean, className: 'text-center' },
        { id: 'msId', name: 'MS Filter', sort: false, type: ColumnType.String },
        { id: 'N/A', name: 'C', sort: false, type: ColumnType.Text, className: 'text-center', condition: 'dossier', notCondition: true },
        { id: 'cascadePriority', name: 'C', sort: false, type: ColumnType.Integer, className: 'text-center', condition: 'dossier' },
    ];

    
    const onSortOK = (d: ISortData[]) => {
        const newItems: IReportBaseFilter[] = [];
        for (const m of d) {
            const m2 = items.filter(q => q.name === m.id)[0];
            newItems.push({ ...m2 , sortOrder: m.sortOrder});
        }
        setFieldValue("filters", getJSONForReportFilterSave(newItems));
        setShowModal(ModalTypes.None);
    };

    const onSort = () => {
        const list: ISortData[] = [];
        for (const item of items) {
            list.push({ id: item.name, name: item.label, sortOrder: item.sortOrder });
        }
        setSortList(list);
        setShowModal(ModalTypes.Sort);
    };

    return (
        <>
            <OERow>
                <OECol sm={2}>{label || 'Filters'}</OECol>
                <OECol sm={10}>
                    <OETable
                        columns={isReport ? reportColumns : columns }
                        data={items}
                        className="table-bordered m-t-5"
                        noDataMessage="No filters values have been assigned"
                        actions={[
                            { icon: Icon.Add, text: 'Add Filter', action: onAddFilter },
                            { icon: Icon.Add, text: 'Add Dossier Filter', action: onAddDossierFilter, hidden: !isReport },
                            { hidden: items.length < 2, icon: Icon.Sort, text: 'Sort Filters', action: onSort },
                        ]}
                    />
                </OECol>
            </OERow>
            {showModal !== ModalTypes.None && !isReport && (
                <FilterFormik onCancel={onCancel} isEditing={showModal === ModalTypes.Edit} onSubmit={onSubmit} item={item} />
            )}
            {showModal !== ModalTypes.None && isReport && (
                <ReportFilterFormik onCancel={onCancel} isEditing={showModal === ModalTypes.Edit} onSubmit={onSubmit} item={item} />
            )}
            {showModal === ModalTypes.Sort && (
                <OESort show items={sortList} title={`Sort Menus`} onOk={onSortOK} onCancel={onCancel} />
            )}
        </>
    );
};

export default Filters;
