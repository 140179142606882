import OEModal, { ModalSize, OEModalBody } from '../../core/components/general/OEModal';

interface IReportDescriptionInfo {
    title: string;
    description: string;
    onCancel: () => void;
}

const ReportDescription: React.FunctionComponent<IReportDescriptionInfo> = ({ title, description, onCancel }) => {

    return (
        <OEModal oeSize={ModalSize.Medium} show={true} onHide={onCancel} title={title}>
            <OEModalBody>
                <div className="report-description">
                    {description}
                </div>
            </OEModalBody>
        </OEModal >
    );
};

export default ReportDescription;