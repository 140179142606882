import React, { useEffect, useState } from 'react';
import OEIcon from '../../../core/components/general/OEIcon';
import { Icon } from '../../../core/entities/Icon';
import { defaultReportFlag, getReportFlagList, IReportFlag } from '../../../reporting/entities/ReportFlag';
import { useGetReportFlag } from '../../../reporting/services/ReportFlagService';
import ReportFlagFormik from './ReportFlagFormik';

interface IReportFlagInfo {
    item: IReportFlag;
}

const ReportFlag: React.FunctionComponent<IReportFlagInfo> = ({ item }) => {
    const { service: reportFlagService, setItem: setReportFlagSearch } = useGetReportFlag();

    const [show, setShow] = useState<boolean>(false);
    const [flags, setFlags] = useState<IReportFlag[]>([]);
    const [flag, setFlag] = useState<IReportFlag>(defaultReportFlag);

    useEffect(() => {
        refreshFlag();
        // eslint-disable-next-line
    }, [item]);

    useEffect(() => {
        if (reportFlagService.result) {
            setFlags(getReportFlagList(reportFlagService.result));
        }
        // eslint-disable-next-line
    }, [reportFlagService]);

    useEffect(() => {
        const i: IReportFlag = getItemFlag();
        if (flags.length > 0) {
            setFlag({ ...flags[0], report: i.report, school: i.school, district: i.district });
        } else {
            setFlag({ ...i, comments: '' });
        }
        // eslint-disable-next-line
    }, [flags]);

    const getItemFlag = (): IReportFlag => {
        return {
            report: item.report,
            reportId: item.reportId,
            academicYear: item.academicYear,
            academicYearLabel: item.academicYearLabel,
            schoolId: item.schoolId && item.schoolId.length > 0 ? item.schoolId : 'All',
            districtId: item.districtId && item.districtId.length > 0 ? item.districtId : 'All',
            school: item.school || 'All Schools',
            district: item.district || 'All Districts',
            comments: ''
        }
    }

    const refreshFlag = () => {
        const i: IReportFlag = getItemFlag();
        setFlag({ ...i });
        setReportFlagSearch({
            ...defaultReportFlag,
            reportId: i.reportId,
            academicYear: i.academicYear,
            schoolId: i.schoolId,
            districtId: i.districtId
        });
    }

    const onCancel = () => {
        setShow(false);
    };

    const onSuccess = () => {
        setShow(false);
        refreshFlag();
    };

    const onShow = () => {
        setShow(true);
    };

    return (
        <span className="m-l-5">
            {flag.comments === '' && (
                <OEIcon hidden={flag.reportId === ''} icon={Icon.FlagOff} onClick={onShow} />
            )}
            {flag.comments !== '' && (
                <span style={{ color: "#CE2D27" }}>
                    <OEIcon hidden={flag.reportId === ''} icon={Icon.FlagOn} onClick={onShow} />
                </span>
            )}
            {show && (
                <ReportFlagFormik item={flag} onCancel={onCancel} onSuccess={onSuccess} />
            )}
        </span>
    );
};

export default ReportFlag;