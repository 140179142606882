import React, { useEffect, useState } from 'react';
import { Route } from 'react-router';
import { Routes } from "react-router-dom";
import { ToastProvider } from 'react-toast-notifications';
import AlertSubscriptionsList from './alert-subsciptions/components/AlertSubscriptionsList';
import ArtifactList from './artifacts/components/ArtifactList';
import { redirectLogin } from './core/services/Authentication';
import './css/controls.css';
import './css/reporting.css';
import './css/styles.css';
import { DatasetConnectionList } from './dataset-connections/components/DatasetConnectionList';
import DcFileUploadList from './dc-file-upload/components/DcFileUploadList';
import DcFormsList from './dc-forms/components/DcFormsList';
import { DcFormsView } from './dc-forms/components/DcFormsView';
import { AnalystDictionary } from './dictionaries/components/AnalystDictionary';
import { ElementDictionary } from './dictionaries/components/ElementDictionary';
import HelpPage from './help/HelpPage';
import MicrostrategyCubeDefinition from './misc/debugging/MicrostrategyCubeDefinition';
import MicrostrategyCubeFilters from './misc/debugging/MicrostrategyCubeFilters';
import MSEmbeddedTierOne from './misc/debugging/templates/single-tier/MSEmbeddedTierOne';
import OrganizationAdmin from './organization/OrganizationAdmin';
import PublicPage from './public/PublicPage';
import MSIframe from './report-microstrategy/components/report-iframe/MSIframe';
import MSEmbeddedPage from './report-microstrategy/components/report-page/MSEmbeddedPage';
import MSEmbeddedTierThree from './report-microstrategy/components/templates/three-tier/MSEmbeddedTierThree';
import BIEmbeddedPage from './report-powerbi/components/BIEmbeddedPage';
import BIEmbeddedTierOne from './report-powerbi/components/BIEmbeddedTierOne';
import ReportAdmin from './reporting/components/admin-reports/ReportAdmin';
import ReportList from './reporting/components/report-list/ReportList';
import SiteReports from './reporting/components/site-reports/SiteReports';
import Dashboard from './site/Dashboard';
import Maintenance from './site/Maintenance';
import Oops from './site/Oops';
import PageNotFound from './site/PageNotFound';
import SiteAdmin from './site/SiteAdmin';
import Layout from './site/components/Layout';
import { IRoute, getRouteList } from './site/entities/Route';
import { ISiteSettings, defaultSiteSettings } from './site/entities/SiteSettings';
import { useGetUserRoutes } from './site/services/RouteService';
import { useGetSiteSettings } from './site/services/SiteSettingsService';
import StyleGuide from './style-guide/StyleGuide';
import UserAdmin from './user/UserAdmin';
import UserProfile from './user/UserProfile';
import Glossary from './user/components/glossary/Glossary';
import WidgetAdmin from './widgets/WidgetAdmin';
import GlossaryTable from './user/components/glossary/GlossaryTable';

const AppComponents = {
    AlertSubscriptionsList: 'AlertSubscriptionsList',
    AnalystDictionary: 'AnalystDictionary',
    ArtifactList: 'ArtifactList',
    BIEmbeddedPage: 'BIEmbeddedPage',
    BIEmbeddedPageTierOne: 'BIEmbeddedPageTierOne',
    Dashboard: 'Dashboard',
    DatasetConnectionList: 'DatasetConnectionList',
    DcFormsList: 'DcFormsList',
    DcFormsView: 'DcFormsView',
    DcFileUploadList: 'DcFileUploadList',
    ElementDictionary: 'ElementDictionary',
    Help: 'Help',
    Glossary: 'Glossary',
    GlossaryTable: 'GlossaryTable',
    Maintenance: 'Maintenance',
    MSEmbeddedPage: 'MSEmbeddedPage',
    MSEmbeddedTierOne: 'MSEmbeddedTierOne',
    MSEmbeddedTierThree: 'MSEmbeddedTierThree',
    MSIframe: 'MSIframe',
    Oops: 'Oops',
    OrganizationAdmin: 'OrganizationAdmin',
    ReportAdmin: 'ReportAdmin',
    ReportList: 'ReportList',
    StyleGuide: 'StyleGuide',
    SiteAdmin: 'SiteAdmin',
    SiteReports: 'SiteReports',
    UserAdmn: 'UserAdmin',
    UserProfile: 'UserProfile',
    WidgetAdmin: 'WidgetAdmin',
    // DcFileUpload: 'DcFileUpload',
    // DcFilePreview: 'DcFilePreview',
    // PublicPage: 'PublicPage',
    // UserLogin: 'UserLogin',
};

const Viewers: any = {};
Viewers[AppComponents.AlertSubscriptionsList] = <AlertSubscriptionsList />;
Viewers[AppComponents.AnalystDictionary] = <AnalystDictionary />;
Viewers[AppComponents.ArtifactList] = <ArtifactList />;
Viewers[AppComponents.BIEmbeddedPage] = <BIEmbeddedPage />;
Viewers[AppComponents.BIEmbeddedPageTierOne] = <BIEmbeddedTierOne />;
Viewers[AppComponents.Dashboard] = <Dashboard />;
Viewers[AppComponents.DatasetConnectionList] = <DatasetConnectionList />;
Viewers[AppComponents.DcFormsList] = <DcFormsList />;
Viewers[AppComponents.DcFormsView] = <DcFormsView />;
Viewers[AppComponents.DcFileUploadList] = <DcFileUploadList />;
Viewers[AppComponents.ElementDictionary] = <ElementDictionary />;
Viewers[AppComponents.Glossary] = <Glossary />;
Viewers[AppComponents.GlossaryTable] = <GlossaryTable />;
Viewers[AppComponents.Help] = <HelpPage />;
Viewers[AppComponents.Maintenance] = <Maintenance />;
Viewers[AppComponents.MSEmbeddedPage] = <MSEmbeddedPage />;
Viewers[AppComponents.MSEmbeddedTierOne] = <MSEmbeddedTierOne />;
Viewers[AppComponents.MSEmbeddedTierThree] = <MSEmbeddedTierThree />;
Viewers[AppComponents.MSIframe] = <MSIframe />;
Viewers[AppComponents.Oops] = <Oops />;
Viewers[AppComponents.OrganizationAdmin] = <OrganizationAdmin />;
Viewers[AppComponents.ReportAdmin] = <ReportAdmin />;
Viewers[AppComponents.ReportList] = <ReportList />;
Viewers[AppComponents.SiteAdmin] = <SiteAdmin />;
Viewers[AppComponents.SiteReports] = <SiteReports />;
Viewers[AppComponents.StyleGuide] = <StyleGuide />;
Viewers[AppComponents.UserAdmn] = <UserAdmin />;
Viewers[AppComponents.UserProfile] = <UserProfile />;
Viewers[AppComponents.WidgetAdmin] = <WidgetAdmin />;

const getComponent = (name: string): any => {
    return Viewers[name.trim()];
};

const App: React.FunctionComponent = () => {
    const { service, doRefresh } = useGetUserRoutes();

    const [routes, setRoutes] = useState<IRoute[]>([]);
    const { service: settingService } = useGetSiteSettings();
    const [settings, setSettings] = useState<ISiteSettings>(defaultSiteSettings);

    useEffect(() => {
        if (settingService.result !== undefined) {
            setSettings(settingService.result);
        }
    }, [settingService]);

    useEffect(() => {
        // for public page we ignore not getting settings, all other pages should redirect to login if not authorized
        if (!window.location.pathname.includes('public-')) {
            if (typeof settings === 'number') {
                redirectLogin();
            } else {
                if (settings.logoutRedirect !== '') {
                    doRefresh();
                }
                else {

                }
            }
        }
        // eslint-disable-next-line
    }, [settings]);

    useEffect(() => {
        if (service.result) {
            const r: IRoute[] = getRouteList(service.result).filter(q => q.isActive);
            setRoutes(r);
        }
        // eslint-disable-next-line
    }, [service]);

    const CheckAuthorization = () => {
        return <ToastProvider>
            {window.location.pathname.includes('public-') && <PublicPage />}
            {!window.location.pathname.includes('public') && routes.length > 0 && (
                <Layout settings={settings} >
                    <Routes>
                        <Route path="/" element={<Dashboard />} />
                        {routes.map((item: IRoute) => (
                            <Route
                                key={item.id}
                                path={item.path}
                                element={getComponent(item.component)}
                            />
                        ))}
                        <Route path="debug-cubedefinition" element={<MicrostrategyCubeDefinition />} />
                        <Route path="debug-cubefilters" element={<MicrostrategyCubeFilters />} />
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                </Layout>
            )}
        </ToastProvider>
    }


    return CheckAuthorization();
};
export default App;
