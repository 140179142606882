/* eslint-disable react/jsx-no-undef */
import React from 'react';
import { IMicroStrategyDossierChapter } from '../../entities/MicrostrategyDossierChapter';
import { IMicroStrategyDossierPage } from '../../entities/MicrostrategyDossierPage';
import { setDossierSelectedPage } from '../../services/MicrostrategyDossierService';
import OELink from '../../../core/components/general/OELink';
declare global { var msLogin: any; }

interface IComponentInfo {
    page: IMicroStrategyDossierPage;
    dossier: any;
    currentIndex: string;
    pageIndex: number;
    chapterIndex: number;
    chapter: IMicroStrategyDossierChapter;
    setCurrentIndex: (i: string) => void;
    isLink: boolean;
}

const MicrostrategyPageSection: React.FunctionComponent<IComponentInfo> = ({ chapter, page, isLink,  setCurrentIndex, currentIndex, dossier, pageIndex, chapterIndex }) => {

    const onSelect = () => {
        setDossierSelectedPage(dossier, chapterIndex, pageIndex, onChapterSet);
    }

    const onChapterSet = () => {
        setCurrentIndex(`${chapterIndex}-${pageIndex}`);
        dossier.navigateToPage(chapter.getFirstPage());
        let index: number = 0;
        while (index < pageIndex) {
            dossier.goToNextPage();
            index = index + 1;
        }
    }

    return (
        <>
            {isLink && (
                <OELink onClick={onSelect} data-rb-event-key={`group`}
                    aria-controls={`reports-tabpane-group`} aria-selected="true"
                    className={`paginaton-link ${currentIndex === `${chapterIndex}-${pageIndex}` ? 'paginaton-link-active' : ''}`}>
                    {page.name}
                </OELink>
            )}
            {!isLink && (
                <button onClick={onSelect} role="tab" data-rb-event-key={`group`}
                    aria-controls={`reports-tabpane-group`} aria-selected="true"
                    className={`nav-item nav-link ${currentIndex === `${chapterIndex}-${pageIndex}` ? 'active' : ''}`}>
                    {page.name}
                </button>
            )}
        </>
    );
};

export default MicrostrategyPageSection;

