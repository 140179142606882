import { IBaseAuditedEntity } from "../../core/entities/BaseAuditedEntity";

export interface IGlossary extends IBaseAuditedEntity {
    id: string;
    term: string;
    definition: string;
    group: string;
}

export interface IGlossaryGroup extends IBaseAuditedEntity {
    name: string;
    itemCount: number;
    items: IGlossary[];    
}

export const defaultGlossary: IGlossary = {
    id: '',
    term: '',
    definition: '',
    group: ''
};

export const defaultGlossaryGroup: IGlossaryGroup = {
    name: "",
    itemCount: 0,
    items: [defaultGlossary]
};

export const getGlossaryForSave = (i: IGlossary): IGlossary => {
    return i;
};