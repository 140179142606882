/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { IDropdownValues } from '../../../core/components/form/OEDropdown';
import OEMessage from '../../../core/components/messaging/OEMessage';
import OESpinner, { SpinnerStyle } from '../../../core/components/messaging/OESpinner';
import { INotification } from '../../../core/components/messaging/entities/Notification';
import { MessageType } from '../../../core/components/messaging/enums/InformationMessages';
import { getBlankGUID } from '../../../core/utilities/String';
import { refreshPage } from '../../../core/utilities/URL';
import { IReport, canDebugReport, defaultReport, getReportCubeRequest } from '../../../reporting/entities/Report';
import { IReportPage } from '../../../reporting/entities/ReportPage';
import { ProcessingPage, ProcessingPageFilters, logProcessingPageFilters } from '../../../reporting/entities/ReportProcessing';
import { ReportType } from '../../../reporting/entities/ReportType';
import { ISiteReport, defaultSiteReport } from '../../../reporting/entities/SiteReport';
import { useGetEmbeddedReportFilters } from '../../../reporting/services/ReportFilterService';
import { useGetReport } from '../../../reporting/services/ReportService';
import { useGetSiteReport, useGetSiteReportFilters } from '../../../reporting/services/SiteReportService';
import { IMicroStrategyFilter } from '../../entities/MicroStrategyFilters';
import { IReportFilter, ReportNoFilterTextValue, defaultReportFilter, getFiltersFromReportFilters, getReportFilterList } from '../../entities/ReportFilter';
import { CubeAttributeElementResponseSeparator, ICubeAttributeElementResponse, initCubeAttributesElements } from '../../entities/api/CubeAttributeElementResponse';
import { ICubeRequest, defaultCubeRequest } from '../../entities/api/CubeRequest';
import { ICubeAttribute, ICubeResponse, defaultCubeResponse, getDefaultCubeAttribute } from '../../entities/api/CubeResponse';
import { getMSAuthorizationToken, useGetCube, useGetCubeAttributeElements } from '../../services/MicrostrategyService';
import Filters from './Filters';

interface IPageFiltersInfo {
    reportId: string,
    page: IReportPage;
    setNotification: (n: INotification) => void;
    pageProcessingStep: ProcessingPage;
    setPageProcessingStep: (i: ProcessingPage) => void;
    setPageFilters: (i: IMicroStrategyFilter[]) => void;
}

const PageFilters: React.FunctionComponent<IPageFiltersInfo> = ({
    reportId, setNotification, setPageProcessingStep, setPageFilters, pageProcessingStep, page
}) => {
    const { service: reportService, setItemId: setReportId } = useGetReport();
    const { service: embeddedFilterService, doRefresh: loadBaseFilters } = useGetEmbeddedReportFilters(false); // Step 1 - get base filters for all objects
    const { service: siteReportService, setId: setSiteReportId } = useGetSiteReport(''); // Step 2 - get the site report inormation
    const { service: siteReportFilterService, setReport: setSiteReportFilter } = useGetSiteReportFilters(); // Step 3 - get filters for page

    const { service: cubeService, setCube: setGetCube } = useGetCube();
    const { service: cubeGetAttributeService, setCube: setGetCubeAttributeElements } = useGetCubeAttributeElements();

    const [processingStep, setProcessingStep] = useState<ProcessingPageFilters>(ProcessingPageFilters.Idle);
    const [cubeResponse, setCubeResponse] = useState<ICubeResponse>(defaultCubeResponse);
    const [cubeRequest, setCubeRequest] = useState<ICubeRequest>({ ...defaultCubeRequest });
    const [cubeAttributes, setCubeAttributes] = useState<ICubeAttribute[]>([]);
    const [cubeAttributeElements, setCubeAttributeElements] = useState<ICubeAttributeElementResponse[]>([]);
    const [siteReport, setSiteReport] = useState<ISiteReport>(defaultSiteReport);
    const [report, setReport] = useState<IReport>(defaultReport);

    const [siteFilters, setSiteFilters] = useState<IReportFilter[]>([]);
    const [baseFilters, setBaseFilters] = useState<IReportFilter[]>([]);
    const [reportFilters, setReportFilters] = useState<IReportFilter[]>([]);
    const [filters, setFilters] = useState<IMicroStrategyFilter[]>([]);
    const [msFilters, setMSFilters] = useState<IMicroStrategyFilter[]>([]);

    const [attributesToLoad, setAttributesToLoad] = useState<ICubeAttribute[]>([]);
    const [currentAttribute, setCurrentAttribute] = useState<ICubeAttribute>(getDefaultCubeAttribute());

    const [debug] = useState<boolean>(canDebugReport);
    const [message, setMessage] = useState<string>('');
    const [error, setError] = useState<string>('');

    useEffect(() => {
        if (pageProcessingStep === ProcessingPage.PageFiltersLoad) {
            setProcessingStep(ProcessingPageFilters.Start);
        }
        if (pageProcessingStep === ProcessingPage.FatalError) {
            setProcessingStep(ProcessingPageFilters.FatalError);
        }
        // eslint-disable-next-line
    }, [pageProcessingStep]);

    useEffect(() => {
        logProcessingPageFilters(processingStep, debug);
        if (pageProcessingStep === ProcessingPage.FatalError) {
            setProcessingStep(ProcessingPageFilters.FatalError);
            return;
        }
        switch (processingStep) {
            case ProcessingPageFilters.Start:
                if (baseFilters.length === 0) {
                    loadBaseFilters();
                }
                else {
                    setProcessingStep(ProcessingPageFilters.SiteReportLoad);
                }
                break;

            case ProcessingPageFilters.SiteReportLoad:
                if (getBlankGUID(siteReport.id)) {
                    setSiteReportId(ReportType.EmbeddedFilters);
                }
                else {
                    setProcessingStep(ProcessingPageFilters.CreatePageFilters);
                }
                break;

            case ProcessingPageFilters.CreatePageFilters:
                if (filters.length > 0) {
                    setProcessingStep(ProcessingPageFilters.SiteReportFiltersLoad);
                }
                else {
                    setReportId(reportId);
                }
                break;

            case ProcessingPageFilters.SiteReportFiltersLoad:
                setMessage('Loading Page Filters');
                let f: IReportFilter[] = [];
                if (reportFilters.length > 0) {
                    f = reportFilters;
                }
                else {
                    f = JSON.parse(page.filters);
                }
                for (const c of f) {
                    c.filterLoaded = false;
                }
                setReportFilters(f);
                setSiteReportFilter({
                    ...siteReport,
                    pageId: page.id,
                    reportFilters: getFiltersFromReportFilters(f, baseFilters)
                });
                break;

            case ProcessingPageFilters.SiteReportFiltersComplete:
                for (const i of siteFilters) {
                    let match = reportFilters.find(q => q.name.toLowerCase() === i.name.toLowerCase());
                    if (match) {
                        match.values = i.values;
                        match.value = i.selectedValue;
                    }
                }
                setReportFilters([...reportFilters]);
                setProcessingStep(ProcessingPageFilters.CubeLoad);
                break;

            case ProcessingPageFilters.CubeLoad:
                setPageProcessingStep(ProcessingPage.PageFiltersLoad);
                setMessage('Loading Cube Attributes for Page Filters');
                if (cubeAttributes.length > 0 && reportFilters.length > 0) {
                    setProcessingStep(ProcessingPageFilters.AttributesLoadStart);
                }
                else {
                    const c: ICubeRequest = getReportCubeRequest(report);
                    if (c.id !== '') {
                        setCubeRequest({ ...c, tokenId: cubeRequest.tokenId, run: true });
                    }
                    else {
                        setNotification({ message: `Cube Load Failed (PageFilters) ${cubeService.error}`, type: 'error' })
                        setError('Cube ID not set for report, report cannot be displayed');
                        setProcessingStep(ProcessingPageFilters.FatalError);
                    }
                }
                break;

            case ProcessingPageFilters.AttributesLoadStart:
                setAttributesToLoad(cubeAttributes.filter(q => !q.elements));
                break;

            case ProcessingPageFilters.AttributesLoadNext:
                setProcessingStep(ProcessingPageFilters.AttributesLoading);
                break;

            case ProcessingPageFilters.AttributesLoading:
                setAttributesToLoad([...attributesToLoad.filter(q => q.name !== currentAttribute.name && !q.elements)]);
                break;

            case ProcessingPageFilters.AttributesLoaded:
                setProcessingStep(ProcessingPageFilters.UpdateFilters)
                break;

            case ProcessingPageFilters.UpdateFilters:
                const d: IMicroStrategyFilter[] = [];
                for (const c of reportFilters) {
                    d.push({
                        key: c.msId,
                        selection: c.values.filter(q => q.id === c.value)[0].parentId,
                        name: c.msName,
                        externalId: c.externalId,
                        pageFilter: true,
                        values: c.values,
                        value: c.value,
                        label: c.label,
                        cascadePriority: c.cascadePriority,
                        sortOrder: c.sortOrder,
                        displayValue: c.value,
                        rangeIncrements: c.rangeIncrements || "2",
                        isSuppression: false,
                        filterType: c.filterType
                    });
                }
                setMSFilters(d);
                setPageFilters(d);
                break;

            case ProcessingPageFilters.Complete:
                setMessage('');
                setPageProcessingStep(ProcessingPage.Complete);
                break;

            case ProcessingPageFilters.FatalError:
                setMessage('')
                setReportFilters([]);
                setPageFilters([]);
                setReport(defaultReport);
                setPageProcessingStep(ProcessingPage.FatalError);
                break;
        }
        // eslint-disable-next-line
    }, [processingStep]);

    useEffect(() => {
        if (reportId !== '') {
            setProcessingStep(ProcessingPageFilters.Start);
        }
        // eslint-disable-next-line
    }, [reportId]);

    useEffect(() => {
        if (embeddedFilterService.result) {
            setBaseFilters(getReportFilterList(embeddedFilterService.result));
        }
        // eslint-disable-next-line
    }, [embeddedFilterService]);

    useEffect(() => {
        if (baseFilters.length > 0) {
            setProcessingStep(ProcessingPageFilters.SiteReportLoad);
        }
        // eslint-disable-next-line
    }, [baseFilters]);

    useEffect(() => {
        if (siteReportService.result) {
            setSiteReport(siteReportService.result);
        }
        // eslint-disable-next-line
    }, [siteReportService]);

    useEffect(() => {
        if (siteReport.id !== '' && pageProcessingStep !== ProcessingPage.FatalError) {
            setProcessingStep(ProcessingPageFilters.CreatePageFilters);
        }
        // eslint-disable-next-line
    }, [siteReport]);

    useEffect(() => {
        if (reportService.result) {
            setReport(reportService.result.report);
        }
        // eslint-disable-next-line
    }, [reportService]);

    useEffect(() => {
        if (report.id !== '') {
            setProcessingStep(ProcessingPageFilters.SiteReportFiltersLoad);
        }
        // eslint-disable-next-line
    }, [report]);

    useEffect(() => {
        if (siteReportFilterService.isFinished) {
            if (siteReportFilterService.data) {
                setSiteFilters(siteReportFilterService.data.data);
            }
            else {
                setNotification({ message: `Re-loading Site Filters (PageFilters)`, type: 'error' })
                refreshPage();
            }
        }
        // eslint-disable-next-line
    }, [siteReportFilterService]);

    useEffect(() => {
        if (siteFilters.length > 0) {
            setProcessingStep(ProcessingPageFilters.SiteReportFiltersComplete);
        }
        // eslint-disable-next-line
    }, [siteFilters]);

    useEffect(() => {
        if (cubeRequest.id === '' && cubeRequest.run) {
            setNotification({ message: `Cube Request ID empty (DossierFilters) ${cubeService.error}`, type: 'error' })
            setError('Cube ID not set for report, report cannot be displayed');
            setProcessingStep(ProcessingPageFilters.FatalError);
        }
        else if (cubeRequest.tokenId === '') {
            setCubeRequest({ ...cubeRequest, tokenId: getMSAuthorizationToken() });
        }
        else {
            setGetCube(cubeRequest);
        }
        // eslint-disable-next-line
    }, [cubeRequest]);

    useEffect(() => {
        if (!cubeService.isInProgress) {
            if (cubeService.body) {
                setCubeResponse(cubeService.body);
            }
            if (cubeService.error) {
                setNotification({ message: `Getting Cube (PageFilters) ${cubeService.error}`, type: 'error' })
                setCubeRequest(defaultCubeRequest);
                setError('Error occurred loading cube, report cannot be displayed');
                setProcessingStep(ProcessingPageFilters.FatalError);
            }
        }
        // eslint-disable-next-line
    }, [cubeService]);

    useEffect(() => {
        if (cubeResponse.id) {
            setCubeAttributes(cubeResponse.result.definition.availableObjects.attributes);
        }
        // eslint-disable-next-line
    }, [cubeResponse]);

    useEffect(() => {
        if (processingStep === ProcessingPageFilters.CubeLoad) {
            setProcessingStep(ProcessingPageFilters.AttributesLoadStart);
        }
        // eslint-disable-next-line
    }, [cubeAttributes]);

    useEffect(() => {
        if (!cubeGetAttributeService.isInProgress) {
            if (cubeGetAttributeService.body) {
                setCubeAttributeElements(initCubeAttributesElements(cubeGetAttributeService.body));
            }
            if (cubeGetAttributeService.error) {
                setNotification({ message: `Getting Cube Attributes (PageFilters) ${currentAttribute.name} ${cubeGetAttributeService.error}`, type: 'error' })
            }
        }
        // eslint-disable-next-line
    }, [cubeGetAttributeService]);

    useEffect(() => {
        if (processingStep === ProcessingPageFilters.AttributesLoading || processingStep === ProcessingPageFilters.AttributesLoadStart) {
            if (attributesToLoad.length > 0) {
                setCurrentAttribute(attributesToLoad[0]);
            }
            else {
                setCurrentAttribute(getDefaultCubeAttribute());
                setProcessingStep(ProcessingPageFilters.AttributesLoaded);
                const mf: IMicroStrategyFilter[] = [];
                for (const f of reportFilters) {
                    mf.push({
                        key: '',
                        externalId: f.externalId,
                        values: f.values,
                        name: f.name,
                        label: f.label,
                        sortOrder: f.sortOrder,
                        cascadePriority: f.cascadePriority,
                        value: f.value,
                        pageFilter: !f.dossier,
                        displayValue: f.value,
                        selection: '',
                        rangeIncrements: f.rangeIncrements || "2",
                        isSuppression: false,
                        filterType: f.filterType
                    });
                }
                setFilters(mf);
            }
        }
        // eslint-disable-next-line
    }, [attributesToLoad]);

    useEffect(() => {
        if (currentAttribute.name !== '') {
            setMessage(`Loading Cube for Attribute: ${currentAttribute.name}`);

            let rf: IReportFilter = defaultReportFilter;
            for (const r of reportFilters) {
                if (r.msID && rf.msId === r.msId) {
                    rf = r;
                }
                else {
                    if (r.name.trim().toLowerCase() === currentAttribute.name.trim().toLowerCase()) {
                        rf = r;
                    }
                    else if (r.label.trim().toLowerCase() === currentAttribute.name.trim().toLowerCase()) {
                        rf = r;
                    }
                    else if (r.externalId !== '') {
                        const i: string[] = r.externalId.split(',');
                        for (const s of i) {
                            if (rf.name === '' && s.trim().toLowerCase() === currentAttribute.name.trim().toLowerCase()) {
                                rf = r;
                            }
                        }
                    }
                }
                if (rf.name !== '') {
                    break;
                }
            }

            if (rf.name !== '') {
                setGetCubeAttributeElements({ ...cubeRequest, attributeId: currentAttribute.id, run: true });
                rf.msId = currentAttribute.id;
                rf.msName = currentAttribute.name;
            }
            else {
                setProcessingStep(ProcessingPageFilters.AttributesLoadNext);
            }
        }
        // eslint-disable-next-line
    }, [currentAttribute]);

    useEffect(() => {
        if (cubeAttributeElements.length > 0 && currentAttribute.id !== '') {
            const t: IReportFilter = reportFilters.filter(q => q.msId === currentAttribute.id)[0];
            if (t.values) {
                for (const r of t.values) {
                    r.parentId = getDossierFilterValue(t, r, cubeAttributeElements);
                }
            }
            else {
                t.values = [];
                for (const r of cubeAttributeElements) {
                    t.values.push({ id: r.key, name: r.value, parentId: r.key });
                }
            }
            setProcessingStep(ProcessingPageFilters.AttributesLoadNext);
        }
        // eslint-disable-next-line
    }, [cubeAttributeElements]);

    useEffect(() => {
        if (processingStep === ProcessingPageFilters.UpdateFilters) {
            setProcessingStep(ProcessingPageFilters.Complete);
        }
        // eslint-disable-next-line
    }, [msFilters]);

    const getDossierFilterValue = (rf: IReportFilter, v: IDropdownValues, cube: ICubeAttributeElementResponse[]): string => {
        for (const ca of cube) {
            if (rf.value === ReportNoFilterTextValue && rf.dossier) {
                return rf.value;
            }
            try {

                let matchValue: string = '';
                let rawValue: string = ca.rawValue;

                // Schools and District have multiple raw values so we must match on id-name values
                for (const rv of rawValue.split(CubeAttributeElementResponseSeparator)) {
                    matchValue = `${v.id.replace(/-/g, '')}-${v.name.toLowerCase()}`.replace(/^0+/, '');
                    if (rv.toLowerCase() === matchValue.toLowerCase()) {
                        return ca.key;
                    }
                }

                // match on exact value with report filter label
                if (ca.value.toLowerCase().startsWith(v.name)) {
                    return ca.key;
                }

                // match on exact value with report filter value
                if (ca.value.toLowerCase().startsWith(v.id)) {
                    return ca.key;
                }

                // match on exact value with ending ';'
                matchValue = v.id.toLowerCase() + ';'
                if (ca.key.toLowerCase().startsWith(matchValue) || ca.id.toLowerCase().startsWith(matchValue)) {
                    return ca.key;
                }

            }
            catch {
                return '';
            }
        }
        return '';
    }

    const updateFilterValues = (f: IMicroStrategyFilter) => {
        const t: IReportFilter = reportFilters.filter(q => q.name === f.name)[0]
        t.value = f.value;
        t.valueName = f.values.filter(q => q.id === f.value)[0].name;
        setProcessingStep(ProcessingPageFilters.SiteReportFiltersLoad);
    }

    return (
        <>
            <Filters
                updateFilterValues={updateFilterValues}
                filters={filters}
                reportId={reportId}
            />
            <OESpinner message={message} oeStyle={SpinnerStyle.Small} />
            {processingStep === ProcessingPageFilters.FatalError && (
                <OEMessage
                    className="report-errors"
                    hideDismissable={true}
                    message={error}
                    type={MessageType.Error}
                />
            )}

        </>
    );
};

export default PageFilters;
