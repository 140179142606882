import React, { useEffect, useRef, useState } from 'react';
import OENotification from '../../../core/components/messaging/OENotification';
import { INotification, defaultNotification } from '../../../core/components/messaging/entities/Notification';
import { displayHTMLContent } from '../../../core/utilities/Miscellaneous';
import { parseQueryString } from '../../../core/utilities/URL';
import { IReport, canDebugReport, defaultReport } from '../../../reporting/entities/Report';
import { ReportConfigurationType, defaultReportConfigurations, getReportConfigurationValue } from '../../../reporting/entities/ReportConfiguration';
import { IReportFilter, getFiltersFromBaseFilters, getReportFilterList } from '../../entities/ReportFilter';
import { ProcessingPage, logProcessingPage, updateProcessingStep } from '../../../reporting/entities/ReportProcessing';
import { ReportType } from '../../../reporting/entities/ReportType';
import { ISiteReport, defaultSiteReport } from '../../../reporting/entities/SiteReport';
import { useGetEmbeddedReportFilters } from '../../../reporting/services/ReportFilterService';
import { useGetReport } from '../../../reporting/services/ReportService';
import { useGetSiteReport, useGetSiteReportFilters } from '../../../reporting/services/SiteReportService';
import { IMSFilter, getReportFilters, initMSFilters, updateMSFilters } from '../../entities/MicroStrategyFilters';
import Filters from '../report-common/Filters';
import Report from '../report-common/Report';
import TokenValidation from '../report-common/TokenValidation';

declare global { var runCode: any; }
declare global { var microstrategy: any; }

const MSEmbeddedPage: React.FunctionComponent = () => {
    const params: any = parseQueryString();
    const [debug] = useState<boolean>(canDebugReport());
    // const reportId = params['id'] || '';
    const footerRef = useRef<any>();
    const reportRef = useRef<any>();

    const { service: embeddedFilterService, doRefresh: initEmbeddedFilters } = useGetEmbeddedReportFilters(false); // Step 1 - get base filters for all objects
    const { service: reportService, setItemId: setReportId } = useGetReport(); // Step 2 - get the page information
    const { service: siteReportService, setId: setSiteReportId } = useGetSiteReport(''); // Step 3 - get the site report inormation
    const { service: siteReportFilterService, setReport: setSiteReportFilter } = useGetSiteReportFilters(); // Step 4 - get filters for page

    const [validToken, setValidToken] = useState<boolean>(false);
    const [report, setReport] = useState<IReport>(defaultReport);
    const [filterReport, setFilterReport] = useState<boolean>(false);
    const [footer, setFooter] = useState<string>('');
    const [baseFilters, setBaseFilters] = useState<IReportFilter[]>([]);
    const [siteFilters, setSiteFilters] = useState<IReportFilter[]>([]);
    const [pageFilters, setPageFilters] = useState<IReportFilter[]>([]);
    const [filters, setFilters] = useState<IMSFilter[]>([]);
    const [hideApply, setHideApply] = useState<boolean>(false);
    const [reportHeight, setReportHeight] = useState<number>(0);
    const [processingStep, setProcessingStep] = useState<ProcessingPage>(ProcessingPage.Idle);
    const [filterInitialzied, setFilterInitialzied] = useState<boolean>(false);
    const [siteReport, setSiteReport] = useState<ISiteReport>(defaultSiteReport);
    const [notiication, setNotification] = useState<INotification>(defaultNotification);

    useEffect(() => {
        logProcessingPage(processingStep, debug);
        switch (processingStep) {
            case ProcessingPage.PageStart:
                initEmbeddedFilters();
                break;

            case ProcessingPage.PageLoad:
                debug && setNotification({ message: 'Loading Page', type: 'info' });
                setReportId(params.id);
                break;

            case ProcessingPage.Finished:
                updateHeight();
                break;
        }
        // eslint-disable-next-line
    }, [processingStep]);

    useEffect(() => {
        window.addEventListener("resize", windowResized);
        return () => {
            window.removeEventListener("resize", windowResized);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (validToken) {
            setProcessingStep(ProcessingPage.PageStart);
        }
        // eslint-disable-next-line
    }, [validToken]);

    useEffect(() => {
        if (embeddedFilterService.result) {
            setBaseFilters(getReportFilterList(embeddedFilterService.result));
            updateProcessingStep(ProcessingPage.PageLoad, setProcessingStep);
        }
        // eslint-disable-next-line
    }, [embeddedFilterService]);

    useEffect(() => {
        if (reportService.result) {
            setReport(reportService.result.report);
        }
        // eslint-disable-next-line
    }, [reportService]);

    useEffect(() => {
        filterInitialzied && window.setTimeout(() => {
            updateProcessingStep(ProcessingPage.LoadDossierFilters, setProcessingStep);
        }, 2000); // this delay is necessary to refresh cascading filters 
        // eslint-disable-next-line
    }, [filters]);

    useEffect(() => {
        if (report.id !== '') {
            setFooter('');
            updateHeight();
            setPageFilters(getFiltersFromBaseFilters(report.filters, baseFilters).filter(q => !q.dossier));
            updateProcessingStep(ProcessingPage.ReportLoad, setProcessingStep);
        }
        // eslint-disable-next-line
    }, [report]);

    useEffect(() => {
        if (siteReportService.result) {
            setSiteReport(siteReportService.result);
        }
        // eslint-disable-next-line
    }, [siteReportService]);

    useEffect(() => {
        if (siteReport.id !== '') {
            setSiteReportFilter({ ...siteReport, reportFilters: pageFilters });
        }
        // eslint-disable-next-line
    }, [siteReport]);

    useEffect(() => {
        if (siteReportFilterService.isFinished) {
            // setFilterValues(siteReportFilterService.data.data);
            setSiteFilters(siteReportFilterService.data.data);
            //setFilters(getMSFilters(reportFilters, siteReportFilterService.data.data, baseFilters));
        }
        // eslint-disable-next-line
    }, [siteReportFilterService]);

    useEffect(() => {
        if (siteFilters.length > 0) {
            updateFilters();
        }
        setFilterInitialzied(true);
        // eslint-disable-next-line
    }, [siteFilters]);

    useEffect(() => {
        if (pageFilters.length > 0) {
            const f: IMSFilter[] = initMSFilters(report.filters, baseFilters, 1);
            setFilters(f);
            setSiteReportId(ReportType.EmbeddedFilters);
            setHideApply(getReportConfigurationValue(ReportConfigurationType.HideApply, report.configuration));
            debug && setNotification({ message: 'Setting Filters', type: 'info' });
        }
        // eslint-disable-next-line
    }, [pageFilters]);

    const windowResized = () => {
        updateHeight();
    }

    const updateHeight = () => {
        if (reportRef.current) {
            const h: number =
                reportRef.current.offsetTop
                + (footerRef.current ? footerRef.current.getBoundingClientRect().height : 0) + 45;
            setReportHeight(h);
        }
    }

    const updateFilters = () => {
        const f: IMSFilter[] = updateMSFilters(filters, siteFilters);

        for (const i of f.filter(q => !q.dossier)) {
            i.value = filters.filter(q => i.name === q.name).length > 0 ? filters.filter(q => i.name === q.name)[0].value : '';
        }
        const v: IMSFilter[] = [];
        for (const c of filters) {
            if (f.filter(q => q.name === c.name).length > 0) {
                v.push(f.filter(q => q.name === c.name)[0]);
            }
            else {
                v.push(c);
            }
        }
        setFilters(v);
    }

    const updateFilterValues = (f: IMSFilter[], level: number, isCascading: boolean, dossier: boolean) => {
        debug && setNotification({ message: `Filters changed ${dossier} - ${level}`, type: 'info' });
        // setNotification({ message: 'Filters changed', type: 'info' });
        // console.log('Current Report filters', filters, "Site Report", siteReport);
        setSiteReportFilter({ ...siteReport, reportFilters: getReportFilters(f.filter(q => !q.dossier), siteReport) });
        !filterReport && window.setTimeout(() => {
            updateProcessingStep(ProcessingPage.LoadDossierFilters, setProcessingStep);
        }, 1000); // this delay is necssary to prevent initial loading of report from being shown
    }

    const onFilterReport = () => {
        setFilterReport(true);
    }

    return (
        <>
            <OENotification setNotification={setNotification} notification={notiication} />
            <TokenValidation validToken={validToken} setValidToken={setValidToken} />
            <div className="report-embedded m-b-0 three-tier">
                <div className="content">
                    <p className="title m-t-10 m-b-5 m-l-10">{`${report.title}`} </p>

                    {filters.length > 0 && (
                        <Filters
                            filterReport={onFilterReport}
                            level={1}
                            filters={filters.filter(q => !q.dossier)}
                            hideApply={hideApply}
                            setFilters={setFilters}
                            updateFilterValues={updateFilterValues}
                        />
                    )}
                    <div ref={reportRef} className="report">
                        <Report
                            configurations={{ ...defaultReportConfigurations }}
                            processingStep={processingStep}
                            setProcessingStep={setProcessingStep}
                            divId={`report0`}
                            setNotification={setNotification}
                            filters={filters}
                            reportId={report.id}
                            setFooter={setFooter}
                            filterReport={filterReport}
                            setFilterReport={setFilterReport}
                            minSize={300}
                            reportHeight={reportHeight}
                            onFilterReport={onFilterReport}
                            index={0}
                        />
                        {footer && (
                            <div ref={footerRef} className="footer" dangerouslySetInnerHTML={displayHTMLContent(footer)} />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default MSEmbeddedPage;
