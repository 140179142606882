import React from 'react';
import OEButton, { ButtonSize } from '../../../core/components/form/OEButton';
import { IMSFilter } from '../../entities/MicroStrategyFilters';
import Filter from './Filter';

interface IComponentInfo {
    filters: IMSFilter[];
    level: number;
    dossier?: boolean;
    setFilters?: (v: IMSFilter[]) => void;
    filterReport?: () => void;
    hideApply?: boolean;
    updateFilterValues?: (i: IMSFilter[], level: number, cascading: boolean, dossier: boolean) => void;
}

const Filters: React.FunctionComponent<IComponentInfo> = ({ filters, level, updateFilterValues, filterReport, hideApply, dossier }) => {
    const updateFilters = (f: IMSFilter) => {
        let i: IMSFilter;
        if (f.key === '') {
            i = filters.filter(q => q.name === f.name)[0]
        }
        else {
            i = filters.filter(q => q.key === f.key)[0]
        }
        i.value = f.value;
        i.name = f.name;
        i.displayValue = f.displayValue;
        updateFilterValues && updateFilterValues(filters, level, f.isCascading, f.dossier);
    }

    return (
        <>
            {filters.length > 0 && (
                <div className="report-filters">
                    {filters.filter(q => q.isActive && (!dossier || q.dossier === dossier)).map((item, index) =>
                        <Filter
                            filter={item}
                            key={index}
                            setValue={updateFilters}
                        />
                    )}

                    {filterReport && !hideApply && (
                        <div className="col report-filters-page3">
                            <OEButton bSize={ButtonSize.Small} onClick={filterReport} text="Apply" />
                        </div>
                    )}
                </div>
            )}
            <div className="cleardiv" />
        </>
    );
};

export default Filters;
