/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { IDropdownValues } from '../../../core/components/form/OEDropdown';
import { INotification } from '../../../core/components/messaging/entities/Notification';
import { IReport, canDebugReport, getReportCubeRequest } from '../../../reporting/entities/Report';
import { ReportConfigurationType, getReportConfigurationValue } from '../../../reporting/entities/ReportConfiguration';
import { ProcessingDossierFilters, ProcessingReport, logProcessingDossierFilters } from '../../../reporting/entities/ReportProcessing';
import { IMSTRDossierFilter } from '../../entities/MicroStrategyDossier';
import { IMicroStrategyFilter, getDefaultMicroStrategyFilter } from '../../entities/MicroStrategyFilters';
import { isSuppressionFilter } from '../../entities/MicrostrategyDossierFilter';
import { IReportFilter, defaultReportFilter } from '../../entities/ReportFilter';
import { ICubeAttributeElementResponse, ICubeFilterAttribute, getCubeAttributeRawValueFromKey, initCubeAttributesElements } from '../../entities/api/CubeAttributeElementResponse';
import { ICubeAttributeRequest, getDefaultCubeAttributeRequest, getDefaultViewFilter } from '../../entities/api/CubeAttributeRequest';
import { ICubeAttributeResponse, defaultCubeAttributeResponse, getCubeAttributesfromAttributeResponse } from '../../entities/api/CubeAttributeResponse';
import { ICubeRequest, defaultCubeRequest } from '../../entities/api/CubeRequest';
import { ICubeAttribute, ICubeResponse, defaultCubeResponse, getDefaultCubeAttribute } from '../../entities/api/CubeResponse';
import { IDossierDefinition } from '../../entities/api/DossierDefinition';
import { getMSAuthorizationToken, useGetCube, useGetCubeAttributeElements, usePostCubeAttributeElements } from '../../services/MicrostrategyService';
import Filters from './Filters';

interface IDossierFiltersInfo {
    report: IReport;
    setNotification: (n: INotification) => void;
    setLocalDossierFilters: (i: IMicroStrategyFilter[]) => void;
    pageMSFilters: IMicroStrategyFilter[];
    reportProcessingStep: ProcessingReport;
    setReportProcessingStep: (i: ProcessingReport) => void;
    setMessage: (i: string) => void;
    setDebugMessage?: (i: string) => void;
    chapterIndex: number;
    dossierDefinition: IDossierDefinition;
    dossierFilters: IMSTRDossierFilter[];
}

const DossierFilters: React.FunctionComponent<IDossierFiltersInfo> = ({
    report, setNotification, setLocalDossierFilters, pageMSFilters, dossierDefinition,
    reportProcessingStep, setReportProcessingStep, setMessage, setDebugMessage, chapterIndex,
    dossierFilters
}) => {
    const { service: cubeService, setCube: setGetCube } = useGetCube();
    const { service: cubeGetAttributeService, setCube: setGetCubeAttributeElements } = useGetCubeAttributeElements();
    const { service: cubePostAttributeService, setCube: setPostCubeAttributeElements } = usePostCubeAttributeElements();

    const [processingStep, setProcessingStep] = useState<ProcessingDossierFilters>(ProcessingDossierFilters.Idle);
    const [cubeRequest, setCubeRequest] = useState<ICubeRequest>(defaultCubeRequest);
    const [cubeResponse, setCubeResponse] = useState<ICubeResponse>(defaultCubeResponse);
    const [cubeId, setCubeId] = useState<string>('');
    const [cubeIds, setCubeIds] = useState<string[]>([]);
    const [cubeAttributes, setCubeAttributes] = useState<ICubeAttribute[]>([]);
    const [cubeAttributeElements, setCubeAttributeElements] = useState<ICubeAttributeElementResponse[]>([]);
    const [filterAttributes, setFilterAttributes] = useState<ICubeFilterAttribute[]>([]);
    const [cubeAttributeResponse, setCubeAttributeResponse] = useState<ICubeAttributeResponse>(defaultCubeAttributeResponse);
    const [cubeAttributeRequest, setCubeAttributeRequest] = useState<ICubeAttributeRequest>(getDefaultCubeAttributeRequest);

    /// Filter States
    const [reportFilters, setReportFilters] = useState<IReportFilter[]>([]);
    const [filters, setFilters] = useState<IMicroStrategyFilter[]>([]);

    const [attributesToLoad, setAttributesToLoad] = useState<ICubeAttribute[]>([]);
    const [currentAttribute, setCurrentAttribute] = useState<ICubeAttribute>(getDefaultCubeAttribute());
    const [loadingFilters, setLoadingFilters] = useState<IReportFilter[]>([]);
    const [filterToLoad, setFilterToLoad] = useState<IReportFilter>(defaultReportFilter);

    const [reportId, setReportId] = useState<string>('');
    const [debug] = useState<boolean>(canDebugReport);

    useEffect(() => {
        switch (reportProcessingStep) {
            case ProcessingReport.ReportLoad:
                setCubeAttributes([]);
                setCubeAttributeElements([]);
                setFilterAttributes([]);
                setAttributesToLoad([]);
                setCurrentAttribute(getDefaultCubeAttribute());
                setLoadingFilters([]);
                setFilterToLoad(defaultReportFilter);
                break;

            case ProcessingReport.ReportLoading:
                setReportFilters([]);
                setFilters([]);
                break;

            case ProcessingReport.ReportLoaded:
                setProcessingStep(ProcessingDossierFilters.CubeLoadStart);
                break;
        }
        // eslint-disable-next-line
    }, [reportProcessingStep]);

    useEffect(() => {
        logProcessingDossierFilters(processingStep, debug);
        setDebugMessage?.(`${processingStep}`);
        switch (processingStep) {

            case ProcessingDossierFilters.CubeLoadStart:
                setMessage('Loading Cubes For Dossier Attributes');
                if (cubeAttributes.length > 0 && reportFilters.length > 0) {
                    if (cubeAttributes.filter(q => !q.elements).length === 0) {
                        setProcessingStep(ProcessingDossierFilters.FiltersStartLoad);
                    }
                    else {
                        setProcessingStep(ProcessingDossierFilters.AttributesLoadStart);
                    }
                }
                else {
                    if (reportFilters.length > 0) {
                        const c: string[] = [];
                        c.push(getReportConfigurationValue(ReportConfigurationType.CubeID, report.configuration));
                        setCubeId(c[0]);
                        for (const f of reportFilters) {
                            if (f.cubeId) {
                                c.push(f.cubeId);
                            }
                        }
                        setCubeIds(c);
                    }
                    else {
                        setProcessingStep(ProcessingDossierFilters.UpdateFilters);
                    }
                }
                break;

            case ProcessingDossierFilters.CubeLoading:
                if (cubeIds.length > 0) {
                    setCubeRequest({ ...getReportCubeRequest(report), id: cubeIds[0], run: true });
                }
                else {
                    setProcessingStep(ProcessingDossierFilters.AttributesLoadStart);
                }
                break;

            case ProcessingDossierFilters.CubeLoadNextCube:
                const ids: string[] = [...cubeIds.filter(q => q !== cubeRequest.id)];
                setCubeIds(ids);
                break;


            case ProcessingDossierFilters.AttributesLoadStart:
                setAttributesToLoad(cubeAttributes.filter(q => !q.elements));
                break;

            case ProcessingDossierFilters.AttributesLoadNext:
                setProcessingStep(ProcessingDossierFilters.AttributesLoading);
                break;

            case ProcessingDossierFilters.AttributesLoading:
                setAttributesToLoad([...attributesToLoad.filter(q => q.name !== currentAttribute.name && !q.elements)]);
                break;

            case ProcessingDossierFilters.AttributesLoaded:
                setProcessingStep(ProcessingDossierFilters.FiltersStartLoad)
                break;

            case ProcessingDossierFilters.FiltersStartLoad:
                const c: ICubeAttributeRequest = { ...getDefaultCubeAttributeRequest() };
                if (pageMSFilters.length > 0) {
                    const m: IMicroStrategyFilter = pageMSFilters[pageMSFilters.length - 1];
                    let key: string = '';
                    let selection: string = m.selection;
                    for (const a of cubeAttributes) {
                        for (const s of m.externalId.split(',')) {
                            if (s.trim().toLowerCase() === a.name.trim().toLowerCase()) {
                                key = a.id;
                                break;
                            }
                        }
                        if (key !== '') {
                            break;
                        }
                    }
                    selection = selection.replace(m.key, key || m.key);
                    if (selection !== '') {
                        c.viewFilter.operands.push({ operator: 'In', operands: [] });
                        c.viewFilter.operands[c.viewFilter.operands.length - 1].operands.push({ type: 'attribute', id: key });
                        c.viewFilter.operands[c.viewFilter.operands.length - 1].operands.push({ type: 'elements', elements: [{ id: getCubeAttributeRawValueFromKey(selection) }] });
                    }
                }
                setCubeAttributeRequest(c);
                setLoadingFilters([...reportFilters]);
                setProcessingStep(ProcessingDossierFilters.FilterLoadNext);
                break;

            case ProcessingDossierFilters.FilterLoadNext:
                setProcessingStep(ProcessingDossierFilters.FilterLoading);
                break;

            case ProcessingDossierFilters.FilterLoading:
                if (filterToLoad.msId !== '') {
                    let match: IReportFilter = defaultReportFilter;
                    if (reportFilters.filter(q => q.msId === filterToLoad.msId).length > 0) {
                        match = reportFilters.filter(q => q.msId === filterToLoad.msId)[0];
                    }
                    if (match.id !== '' && match.values.length > 0) {
                        let value: IDropdownValues = match.values[0];
                        if (match.values.filter(q => q.id === match?.value).length > 0) {
                            value = match.values.filter(q => q.id === match?.value)[0];
                        }
                        cubeAttributeRequest.viewFilter.operands.push({ operator: 'In', operands: [] });
                        cubeAttributeRequest.viewFilter.operands[cubeAttributeRequest.viewFilter.operands.length - 1].operands.push({ type: 'attribute', id: filterToLoad.msId });
                        cubeAttributeRequest.viewFilter.operands[cubeAttributeRequest.viewFilter.operands.length - 1].operands.push({ type: 'elements', elements: [{ id: getCubeAttributeRawValueFromKey(value.parentId) }] });
                    }
                }

                setLoadingFilters([...loadingFilters.filter(q => q.name !== filterToLoad.name)]);
                break;

            case ProcessingDossierFilters.FiltersLoaded:
                setProcessingStep(ProcessingDossierFilters.UpdateFilters)
                break;

            case ProcessingDossierFilters.UpdateFilters:
                const d: IMicroStrategyFilter[] = [];
                for (const c of reportFilters) {
                    const i: IMicroStrategyFilter = {
                        ...getDefaultMicroStrategyFilter(),
                        values: [],
                        key: c.msId,
                        name: c.msName,
                        externalId: '',
                        value: c.value,
                        displayValue: c.value,
                        label: c.label,
                        cascadePriority: c.cascadePriority,
                        sortOrder: c.sortOrder,
                        filterType: c.filterType
                    };
                    if (isSuppressionFilter(c.name)) {
                        d.push({ ...i, selection: c.value, isSuppression: true});
                    }
                    else {
                        d.push({
                            ...i, 
                            selection: c.value ? c.value : (c.values.length > 0 ? c.values[0].id : ''),
                            rangeIncrements: c.rangeIncrements || "2",
                            isSuppression: false
                        });
                    }
                }
                d.sort(function (x, y) {
                    return x.cascadePriority - y.cascadePriority;
                });
                setLocalDossierFilters(d);
                setProcessingStep(ProcessingDossierFilters.Complete)
                break;

            case ProcessingDossierFilters.Complete:
                setReportProcessingStep(ProcessingReport.DossierCreate)
                setCubeRequest({ ...cubeRequest, run: false });
                break;

            case ProcessingDossierFilters.FatalError:
                setReportProcessingStep(ProcessingReport.FatalError)
                break;
        }
        // eslint-disable-next-line
    }, [processingStep]);

    useEffect(() => {
        if (report.id !== reportId) {
            try {
                if (report.filters && report.filters !== '') {
                    let f: IReportFilter[] = [];
                    f = JSON.parse(report.filters);
                    const r: IReportFilter[] = [...f.filter(q => q.dossier && !q.ignoreFilter)];
                    r.sort(function (x, y) {
                        return x.cascadePriority - y.cascadePriority;
                    });
                    setReportFilters(r);
                }
            }
            catch (e: any) {
                setNotification({ message: `Report (DossierFilters) ${e.message}`, type: 'error' })
            }
            setReportId(report.id);
        }
        // eslint-disable-next-line
    }, [report]);

    useEffect(() => {
        if (processingStep === ProcessingDossierFilters.CubeLoadStart || processingStep === ProcessingDossierFilters.CubeLoadNextCube) {
            setProcessingStep(ProcessingDossierFilters.CubeLoading);
        }
        // eslint-disable-next-line
    }, [cubeIds]);

    useEffect(() => {
        if (dossierDefinition.id !== '' && dossierDefinition.chapters && dossierDefinition.chapters.length > 0) {
            try {
                for (const c of filters) {
                    c.hidden = true;
                }

                for (const c of dossierDefinition.chapters[chapterIndex].filters) {
                    let match = filters.find(q => q.name === c.name);
                    if (match) {
                        match.hidden = false;
                    }
                }
                console.log('dossier definition', dossierDefinition, chapterIndex);
                setFilters([...filters]);
            }
            catch {
                console.log('could not update filters', filters);
            }
        }
        // eslint-disable-next-line
    }, [dossierDefinition, chapterIndex]);

    useEffect(() => {
        if (cubeRequest.id === '' && cubeRequest.run) {
            setNotification({ message: `Cube Request ID empty (DossierFilters) ${cubeService.error}`, type: 'error' })
            setMessage('Cube ID not set for report, report cannot be displayed');
            setProcessingStep(ProcessingDossierFilters.FatalError);
        }
        else if (cubeRequest.tokenId === '') {
            setCubeRequest({ ...cubeRequest, tokenId: getMSAuthorizationToken() });
        }
        else {
            setGetCube(cubeRequest);
        }
        // eslint-disable-next-line
    }, [cubeRequest]);

    useEffect(() => {
        if (!cubeService.isInProgress) {
            setCubeRequest({ ...cubeRequest, run: false });
            if (cubeService.body) {
                setCubeResponse(cubeService.body);

            }
            if (cubeService.error) {
                setNotification({ message: `Getting Cube (DossierFilters) ${cubeService.error}`, type: 'error' })
                setCubeRequest(defaultCubeRequest);
                setMessage('Error occurred loading cube, report cannot be displayed');
                setProcessingStep(ProcessingDossierFilters.FatalError);
            }
        }
        // eslint-disable-next-line
    }, [cubeService]);

    useEffect(() => {
        if (cubeResponse.id) {
            const i: ICubeAttribute[] = [...cubeAttributes];
            for (const a of cubeResponse.result.definition.availableObjects.attributes) {
                i.push({ ...a, cubeId: cubeRequest.id });
            }
            setCubeAttributes(i);
        }
        // eslint-disable-next-line
    }, [cubeResponse]);

    useEffect(() => {
        if (cubeAttributes.length > 0 && reportFilters.length > 0) {
            setProcessingStep(ProcessingDossierFilters.CubeLoadNextCube);
        }
        else if (reportFilters.length === 0 && processingStep === ProcessingDossierFilters.CubeLoading) {
            setProcessingStep(ProcessingDossierFilters.UpdateFilters);
        }

        // eslint-disable-next-line
    }, [cubeAttributes]);

    useEffect(() => {
        for (const f of dossierFilters) {
            let match = filters.find(q => q.name === f.filterName);
            if (match) {
                const values: IDropdownValues[] = [];
                for (const v of f.filterDetail.items) {
                    values.push({ id: v.value, name: v.name });
                }
                match.values = values;
            }
        }
        // eslint-disable-next-line
    }, [dossierFilters]);

    useEffect(() => {
        if (!cubeGetAttributeService.isInProgress) {
            if (cubeGetAttributeService.body) {
                setCubeAttributeElements(initCubeAttributesElements(cubeGetAttributeService.body));
            }
            if (cubeGetAttributeService.error) {
                setNotification({ message: `Getting Cube Attributes (DossierFilters) ${currentAttribute.name} ${cubeGetAttributeService.error}`, type: 'error' })
                console.log(cubeGetAttributeService.error);
            }
        }
        // eslint-disable-next-line
    }, [cubeGetAttributeService]);

    useEffect(() => {
        if (processingStep === ProcessingDossierFilters.AttributesLoading || processingStep === ProcessingDossierFilters.AttributesLoadStart) {
            if (attributesToLoad.length > 0) {
                setCurrentAttribute(attributesToLoad[0]);
            }
            else {
                setCurrentAttribute(getDefaultCubeAttribute());
                setProcessingStep(ProcessingDossierFilters.AttributesLoaded);
            }
        }
        // eslint-disable-next-line
    }, [attributesToLoad]);

    useEffect(() => {
        if (currentAttribute.name !== '') {
            setMessage(`Loading Cube for Attribute: ${currentAttribute.name}`);
            let rf: IReportFilter = defaultReportFilter;
            for (const r of reportFilters) {
                if (currentAttribute.id === r.msId) {
                    rf = r;
                }
                else {
                    if (r.name === currentAttribute.name) {
                        rf = r;
                    }
                    else if (r.label === currentAttribute.name) {
                        rf = r;
                    }
                    else if (r.externalId !== '') {
                        const i: string[] = r.externalId.split(',');
                        for (const s of i) {
                            if (rf.name === '' && s === currentAttribute.name) {
                                rf = r;
                            }
                        }
                    }
                }
                if (rf.name !== '') {
                    break;
                }
            }

            if (rf.name !== '') {
                setGetCubeAttributeElements({ ...cubeRequest, id: currentAttribute.cubeId, attributeId: currentAttribute.id, run: true });
                rf.msId = currentAttribute.id;
            }
            else {
                setProcessingStep(ProcessingDossierFilters.AttributesLoadNext);
            }
        }
        // eslint-disable-next-line
    }, [currentAttribute]);

    useEffect(() => {
        if (cubeAttributeElements.length > 0 && currentAttribute.id !== '') {
            cubeAttributes.filter(q => q.name === currentAttribute.name)[0].elements = [...cubeAttributeElements];
            setProcessingStep(ProcessingDossierFilters.AttributesLoadNext);
        }
        // eslint-disable-next-line
    }, [cubeAttributeElements]);

    useEffect(() => {
        if (processingStep === ProcessingDossierFilters.FilterLoading) {
            if (loadingFilters.length > 0) {
                setFilterToLoad(loadingFilters[0]);
            }
            else {
                setFilterToLoad({ ...defaultReportFilter });
                setProcessingStep(ProcessingDossierFilters.FiltersLoaded);
                const mf: IMicroStrategyFilter[] = [];
                for (const f of reportFilters) {
                    mf.push({
                        key: '',
                        externalId: f.externalId,
                        values: f.values,
                        name: f.name,
                        label: f.label,
                        value: f.value,
                        displayValue: f.value,
                        pageFilter: !f.dossier,
                        selection: f.defaultSelection || '',
                        cascadePriority: f.cascadePriority,
                        sortOrder: f.sortOrder,
                        rangeIncrements: f.rangeIncrements || "0",
                        supportMultiple: f.mulitipleSelect,
                        isSuppression: isSuppressionFilter(f.name),
                        filterType: f.filterType
                    });
                }
                mf.sort(function (x, y) {
                    return x.cascadePriority - y.cascadePriority;
                });

                for (const f of filters) {
                    mf.filter(q => q.name === f.name)[0].hidden = f.hidden;
                }
                setFilters(mf);

            }
        }
        // eslint-disable-next-line
    }, [loadingFilters]);

    useEffect(() => {
        if (filterToLoad.name !== '') {
            setMessage(`Loading Attributes for Filter:  ${filterToLoad.name}`);
            const t: IReportFilter = reportFilters.filter(q => q.name === filterToLoad.name)[0];

            let match = cubeAttributes.find(q => q.name.toLowerCase() === filterToLoad.name.toLowerCase());
            if (!match) {
                match = cubeAttributes.find(q => q.name.toLowerCase() === filterToLoad.label.toLowerCase());
            }

            if (!match) {
                match = cubeAttributes.find(q => q.id === t.msId);
            }

            if (!match && t.externalId !== '') {
                const i: string[] = t.externalId.split(',');
                for (const s of i) {
                    if (!match) {
                        match = cubeAttributes.find(q => q.name.toLowerCase() === s.toLowerCase());
                    }
                }
            }

            if (match) {
                cubeAttributeRequest.requestedObjects.attributes = [];
                cubeAttributeRequest.requestedObjects.attributes.push({ id: match.id });

                const b: ICubeRequest = { ...cubeRequest, id: cubeId, body: cubeAttributeRequest, run: true };

                if (t.cubeId) {
                    b.id = t.cubeId;
                    b.body = { ...cubeAttributeRequest, viewFilter: getDefaultViewFilter() };
                }
                setPostCubeAttributeElements(b);

                t.msId = match.id;
                t.msName = match.name;
            }
            else {
                setProcessingStep(ProcessingDossierFilters.FilterLoadNext);
            }
            setReportFilters(reportFilters);
        }
        // eslint-disable-next-line
    }, [filterToLoad]);

    useEffect(() => {
        if (!cubePostAttributeService.isInProgress) {
            if (cubePostAttributeService.body) {
                setCubeAttributeResponse(cubePostAttributeService.body);
            }
            if (cubePostAttributeService.error) {
                setNotification({ message: `Getting Cube Attribute Elements (DossierFilters) ${filterToLoad.name} ${cubePostAttributeService.error}`, type: 'error' })
                setProcessingStep(ProcessingDossierFilters.FilterLoadNext);
            }
        }
        // eslint-disable-next-line
    }, [cubePostAttributeService]);

    useEffect(() => {
        try {
            if (cubeAttributeResponse.result) {
                setFilterAttributes(getCubeAttributesfromAttributeResponse(cubeAttributeResponse.result.data.root.children));
            }
        }
        catch (e: any) {
            setNotification({ message: `Report (cubeAttributeResponse) ${e.message}`, type: 'error' })
            console.log(cubeAttributeResponse.result);
        }
        // eslint-disable-next-line
    }, [cubeAttributeResponse]);

    useEffect(() => {
        if (filterAttributes.length > 0 && filterToLoad.name !== '') {
            const t: IReportFilter = reportFilters.filter(q => q.name === filterToLoad.name)[0];
            if (t) {
                t.values = [];
                let match = cubeAttributes.find(q => q.name === filterToLoad.name);
                if (match && match.elements) {
                    for (const r of filterAttributes) {
                        let value = match.elements.find(q => q.key === r.id);
                        if (value) {
                            t.values.push({ id: r.id, name: value.value, parentId: r.id });
                        }
                    }
                }
                setReportFilters(reportFilters);
            }
            setProcessingStep(ProcessingDossierFilters.FilterLoadNext);
        }
        // eslint-disable-next-line
    }, [filterAttributes]);

    const updateFilterValues = (f: IMicroStrategyFilter) => {
        if (processingStep === ProcessingDossierFilters.Complete) {
            reportFilters.filter(q => q.name === f.name)[0].value = f.value;
            setReportFilters(reportFilters);
            setProcessingStep(ProcessingDossierFilters.UpdateFilters);
        }
    }

    return (
        <>
            {dossierDefinition.id !== '' && (
                <Filters
                    reportId={report.id}
                    updateFilterValues={updateFilterValues}
                    filters={filters}
                />
            )}
        </>
    );
};

export default DossierFilters;
